export const UrlConstants = {
  Auth:{
    Login: 'api/auth/login',
    Logout:'api/auth/logout'
  },
  Token:{
    Generate:'api/actiontoken/generate/:type/:connect',
  },
  Employees: {
    List: 'api/employees/list',
    All: 'api/employees/all',
    Get: 'api/employees/:userId',
    Create: 'api/employees/create',
    Update: 'api/employees/:userId',
    Delete: 'api/employees/:userId',
    RegisterPhone: 'api/employees/registerPhone',
    EmployeeByMobileItn: 'api/employees/getEmployeeByMobileItn',
    Import: 'api/employees/import',
    Export: 'api/employees/export',
    UpdateEmployees: 'api/employees/updateEmployees'
  },
  Settings: {
    All: 'api/settings',
    Update: 'api/settings/update'
  },
  TimeZone: {
    List: 'api/timezone/list'
  },
  Administrators: {
    List: 'api/admin/list',
    All: 'api/admin/all',
    Create: 'api/admin/create',
    Delete: 'api/admin/:userId'
  },
  Report:{
    Timesheet: 'api/report/timesheet',
    Workdays: 'api/report/workdays',
    Workday: 'api/report/workday',
    Organization: 'api/report/organization',
  },
  App: {
    Version: 'api/app/version'
  },
  Organizations: {
    All: 'api/organizations/all',
    Create: 'api/organizations/create',
    Get: 'api/organizations/:organizationId',
    Update: 'api/organizations/:organizationId',
    Delete: 'api/organizations/:organizationId',
    GetCountEmplyees: 'api/organizations/:organizationId/employees',
  },
  ObjectOrganization: {
    All: 'api/objectorganizations/all',
    Create: 'api/objectorganizations/create',
    Get: 'api/objectorganizations/:objectorganizationsId',
    Update: 'api/objectorganizations/:objectorganizationsId',
    Delete: 'api/objectorganizations/:objectorganizationsId'
  },
  ChartWork: {
    All: 'api/chartwork/all',
    Create: 'api/chartwork/create',
    Get: 'api/chartwork/:chartworkId',
    Update: 'api/chartwork/:chartworkId',
    Delete: 'api/chartwork/:chartworkId'
  },
  OrganizationSettings: {
    GetSettings: 'api/organizationSettings/getSettings',
    SetSettings: 'api/organizationSettings/setSettings',
    GenerateToken: 'api/organizationSettings/generateToken',
  },
  Managers: {
    GetByOrganization: 'api/manager/getByOrganization',
    Create: 'api/manager/create',
    Update: 'api/manager',
    Delete: 'api/manager/:id',
    GetOrganizationsByManagerId: 'api/manager/:id',
    UpdateRole: 'api/manager/updateRole'
  },
  EmploeeObjectsOrganization: {
    Create: 'api/employeesobjects/create',
    Get: 'api/employeesobjects/:emploeeId',
    Delete: 'api/employeesobjects/delete'
  },
  Errand: {
    All: 'api/errand/all',
    Create: 'api/errand/create',
    Get: 'api/errand/:errandId',
    Update: 'api/errand/:errandId',
    Delete: 'api/errand/:errandId'
  },
  ErrandEmployees: {
    Create: 'api/errandemployees/create',
    Get: 'api/errandemployees/getemployees/:errandId',
    Delete: 'api/errandemployees/:errandId'
  },
  Role: {
    All: 'api/role/all'
  },
}
