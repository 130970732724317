import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UrlConstants } from '../constants/app.url.constants';
import { TimeZone } from '../models/time-zone.model';


@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getTimeZones(): Promise<Array<TimeZone>> {
    return this.http.get(this.baseUrl + UrlConstants.TimeZone.List).pipe(
      map((res: Array<TimeZone>) => res)
    ).toPromise();
  }
}
