import { DatePipe } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { WorkdayRequest } from 'src/app/models/workday-request.model';
import { Workday, WorkdayResponse } from 'src/app/models/workday-response.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { ReportService } from 'src/app/services/report.service';
import * as XLSX from 'xlsx';
import { NgCalendarLocale } from 'src/app/locales/ru.locale';
import { EmployeeWorkDay } from 'src/app/models/employee_workday.model';


@Component({
  selector: 'app-workday-one',
  templateUrl: './workday_one.component.html',
  styleUrls: ['./workday_one.component.css'],
  
})


export class WorkdayOneComponent implements OnInit {

  calendarLocale = NgCalendarLocale;
  selectedEmployee: Employee[] = [];
  employees: Employee[] = [];
  employeesWorksDays: EmployeeWorkDay[] = []

  date: Date = new Date();
  yearRange = '2000:' + (new Date()).getFullYear().toString();

  employeeWorkday: Workday;
  returnEmployees :Employee[];
  constructor(private employeeService: EmployeeService,
              private reportService: ReportService,
              private activatedRoute: ActivatedRoute,
              private datePipe: DatePipe) {
  }
  async ngOnInit() {
    await this.loadEmployees();

    this.date =  new Date();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['employee'] && params['start'] && params['end']){
        var start = new Date(params['start']);
        var end = new Date(params['end']);
        this.date = start;

        var employee = params['employee'];
        this.selectedEmployee.push(this.employees.find(x => x.id === employee));
        this.getWorkdays();
      }
    });
  }

  async loadEmployees() {
    await this.employeeService.getEmployees().then((data: Employee[]) => {
      this.employees = this.employees.concat(data);
      this.selectedEmployee.push(this.employees[0]);
    });
  }

  getWorkdays() {
    // In case selected only one day and not range
    let request = new WorkdayRequest(this.date, this.date, this.selectedEmployee);
    this.reportService.getWorkday(request).subscribe((data) => {
      this.employeeWorkday = data['workday'];
      this.employeesWorksDays = data['employeeWorkDay'].filter(el=>el["workday"]!=null);   
      
    });
  }

  exportExcel() {
    const worksheet = XLSX.utils.json_to_sheet(this.getReportData());
    worksheet.A1.v = "ФИО";
    worksheet.B1.v = "ИНН";
    worksheet.C1.v = "Время открытия";
    worksheet.D1.v = "Время закрытия";
    worksheet['!cols'] = [
      {wch:10},
      {wch:14},
      {wch:14}
    ];
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'Рабочий день ' + this.getDates() + '.xlsx');
  }

  getReportData() {
    let report = [];
    for (let emp of this.employeesWorksDays) {
      var data = {"fio":emp.employee.fio,"inn":emp.employee.inn,"start":emp.workday.start,"end":emp.workday.end, }
      report.push(data);
    }
    return report;
  }

  getDates() {
    return this.stringifyDate(this.date);
  }

  stringifyDate(date: any){
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
}
