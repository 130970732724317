import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstants } from '../constants/app.url.constants';
import { Errand } from '../models/errand.model';

@Injectable()
export class ErrandService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) {
  }

  getErrands() {
    return this.http.get(this.baseUrl + UrlConstants.Errand.All);
  }

  getErrand(id: string) {
    return this.http.get(this.baseUrl + UrlConstants.Errand.Get.replace(':errandId', id));
  }

  createErrand(errand: Errand) {
    return this.http.post(this.baseUrl + UrlConstants.Errand.Create, errand);
  }

  updateErrand(errand: Errand) {
    return this.http.put(this.baseUrl + UrlConstants.Errand.Update.replace(':errandId', errand.id), errand);
  }
  deleteErrand(id: string) {
    return this.http.delete(this.baseUrl + UrlConstants.Errand.Delete.replace(':errandId', id));
  }
}
