import { Employee } from "./employee.model";
import { ErrandEmployee } from "./errand-employee.model";

export class Errand{
  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public latitude?: string,
    public longitude?: string,
    public organizationId?: string,
    public accuracyError?: string,
    public manageId?: string,
    public employees?:  Array<ErrandEmployee>,
    public employeesSelect?:  ErrandEmployee,
  ) { }
}
