import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UrlConstants } from '../constants/app.url.constants';
import { TimesheetRequest } from '../models/timesheet-request.model';
import { TimesheetResponce } from '../models/timesheet-responce.model';
import { WorkdayRequest } from '../models/workday-request.model'

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient,
              @Inject('BASE_URL') protected baseUrl: string) { }

  getTimesheet(data: TimesheetRequest): Observable<TimesheetResponce> {
    return this.http.put(this.baseUrl + UrlConstants.Report.Timesheet, data).pipe(
      map((res: TimesheetResponce) => res)
    );
  }

  getWorkdays(data: WorkdayRequest) {
    return this.http.put(this.baseUrl + UrlConstants.Report.Workdays, data);
  }

  getWorkday(data: WorkdayRequest) {
    return this.http.put(this.baseUrl + UrlConstants.Report.Workday, data);
  }

  getOrganization() {
    return this.http.get(this.baseUrl + UrlConstants.Organizations.All);
  }
}
