import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Organization } from '../models/organization.model';
import { UrlConstants } from '../constants/app.url.constants';

@Injectable()
export class OrganizationService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) {
  }

  getOrganizations() {
    return this.http.get(this.baseUrl + UrlConstants.Organizations.All);
  }

  getOrganization(id: any) {
    return this.http.get(this.baseUrl + UrlConstants.Organizations.Get.replace(':organizationId', id));
  }

  getCountEmployees(id: any) {
    return this.http.get(this.baseUrl + UrlConstants.Organizations.GetCountEmplyees.replace(':organizationId', id));
  }

  createOrganization(organization: Organization) {
    return this.http.post(this.baseUrl + UrlConstants.Organizations.Create, organization);
  }

  updateOrganization(organization: Organization) {
    return this.http.put(this.baseUrl + UrlConstants.Organizations.Update.replace(':organizationId', organization.id), organization);
  }
  deleteOrganization(id: string) {
    return this.http.delete(this.baseUrl + UrlConstants.Organizations.Delete.replace(':organizationId', id));
  }
}
