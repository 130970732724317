import { ActionTokenEnum } from '../enums/action-token.enum';


export class ActionToken {
  constructor(
    public id?: string,
    public create?: Date,
    public dueDate?: Date,
    public actionDate?: Date,
    public action?: ActionTokenEnum,
    public employeeId?: string
  ) {
    this.create = new Date(create);
    this.dueDate = new Date(dueDate);
    this.actionDate = new Date(actionDate);
  }
}
