import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { WorkdayRequest } from 'src/app/models/workday-request.model';
import { Workday, WorkdayResponse } from 'src/app/models/workday-response.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { ReportService } from 'src/app/services/report.service';
import * as XLSX from 'xlsx';
import { NgCalendarLocale } from 'src/app/locales/ru.locale';

@Component({
  selector: 'app-workday',
  templateUrl: './workday.component.html',
  styleUrls: ['./workday.component.css']
})
export class WorkdayComponent implements OnInit {

  calendarLocale = NgCalendarLocale;

  selectedEmployee: Employee;
  employees: Employee[] = [];

  dateRange: Date[] = [new Date(), new Date()];
  yearRange = '2000:' + (new Date()).getFullYear().toString();

  employeeWorkdays: Workday[];

  constructor(private employeeService: EmployeeService,
              private reportService: ReportService,
              private activatedRoute: ActivatedRoute,
              private datePipe: DatePipe) {
  }

  async ngOnInit() {
    await this.loadEmployees();
    this.dateRange = [new Date(), new Date()];

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['employee'] && params['start'] && params['end']){
        var start = new Date(params['start']);
        var end = new Date(params['end']);
        this.dateRange = [start, end];

        var employee = params['employee'];
        this.selectedEmployee = this.employees.find(x => x.id === employee);

        this.getWorkdays();
      }
    });
  }

  async loadEmployees() {
    await this.employeeService.getEmployees().then((data: Employee[]) => {
      this.employees = this.employees.concat(data);
      this.selectedEmployee = this.employees[0];
    });
  }

  getWorkdays() {
    // In case selected only one day and not range
    if (this.dateRange[1] == null) {
      this.dateRange[1] = this.dateRange[0];
    }

    let request = new WorkdayRequest(this.dateRange[0], this.dateRange[1], [this.selectedEmployee]);
    this.reportService.getWorkdays(request).subscribe((data : WorkdayResponse) => this.employeeWorkdays = data.workdays);
  }

  exportExcel() {
    const worksheet = XLSX.utils.json_to_sheet(this.getReportData());
    worksheet.A1.v = "Дата";
    worksheet.B1.v = "Время открытия";
    worksheet.C1.v = "Время закрытия";
    worksheet['!cols'] = [
      {wch:10},
      {wch:14},
      {wch:14}
    ];
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'Рабочий день ' + this.selectedEmployee.fio + ' ' + this.gatDates() + '.xlsx');
  }

  getReportData() {
    let report = [];
    for (let employeeWorkday of this.employeeWorkdays) {
      employeeWorkday.date = this.datePipe.transform(employeeWorkday.date, 'dd.MM.yyyy');
      report.push(employeeWorkday);
    }
    return report;
  }

  gatDates() {
    if (this.dateRange.length === 0){
      return;
    }

    if (this.dateRange.length === 1){
      return this.stringifyDate(this.dateRange[0]);
    } else if(this.dateRange.length === 2){
      return this.stringifyDate(this.dateRange[0]) + '-' + this.stringifyDate(this.dateRange[1]);
    } else {
      var temp = '';
      this.dateRange.forEach(date => {
        temp = temp + ', ' + this.stringifyDate(date);
      });

      return temp;
    }
  }

  stringifyDate(date: any){
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
}
