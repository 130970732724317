import { Component, OnInit } from '@angular/core';
import { TimeZoneService } from '../../../services/time-zone.service';
import { TimeZone } from '../../../models/time-zone.model';
import { OrganizationSettingsService } from '../../../services/organization-settings.service';
import { ApiResponse } from '../../../models/api-response.model';
import { ApiStatusEnum } from '../../../enums/api-status.enum';
import { NotificationService } from '../../../services/notification.service';
import { OrganizationSettings } from 'src/app/models/organizationsettings.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { check_email, check_number } from 'src/app/utils/encryption.util';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-org-settings',
  templateUrl: './org-settings.component.html',
  styleUrls: ['./org-settings.component.css']
})
export class OrgSettingsComponent implements OnInit {

  settings: OrganizationSettings;
  organizationSettingsCreateForm: FormGroup;    
  timeZones: any;
  organizationEditMode = false; 
  selectedTimeZone: TimeZone;

  constructor(private timeZoneService: TimeZoneService,
    private organizationSettingsService: OrganizationSettingsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService) { }

  async ngOnInit() {  
    this.timeZones = await this.timeZoneService.getTimeZones();
    this.organizationSettingsService.getOrganizationSettings().subscribe((data: OrganizationSettings) => {
      this.settings = data;
      this.selectedTimeZone = this.timeZones.find(x => x.id === this.settings.timeZone);
      this.settings.timeZone = this.selectedTimeZone;
      
    });
    this.organizationSettingsCreateForm = new FormGroup({
      timeZone: new FormControl('', [Validators.required]),
      techEmail: new FormControl(''),
      techNumber: new FormControl(''),
    });
  }

  showOrganizationEditDialog() {
    this.organizationSettingsCreateForm.setValue({
      timeZone: this.selectedTimeZone,
      techEmail: this.settings.techEmail,
      techNumber: this.settings.techNumber
    });
    this.organizationEditMode = true;
  }

  hideOrganizationEditDialog() {
    this.organizationEditMode = false;
    this.organizationSettingsCreateForm.reset();
  }

  onTimezoneDropdownChanged(event) {
    const timezone = event.value;
    if (timezone) {
      this.organizationSettingsCreateForm.setValue({
      timeZone: timezone,
      techEmail: this.settings.techEmail,
      techNumber: this.settings.techNumber
      });
    }
  }

  save() {
    if (!this.organizationSettingsCreateForm.controls['timeZone'].valid) {
      this.notificationService.Error("Заполните часовой пояс");
      return;
    }
    if (!check_email(this.organizationSettingsCreateForm.controls['techEmail'].value)) {
      this.notificationService.Error("Неверный email");
      return;
    }
    if (!check_number(this.organizationSettingsCreateForm.controls['techNumber'].value)) {
      this.notificationService.Error("Неверный email");
      return;
    }
    this.settings = new OrganizationSettings();
    this.settings.timeZone = this.organizationSettingsCreateForm.controls['timeZone'].value['id'];
    this.settings.techEmail = this.organizationSettingsCreateForm.controls['techEmail'].value;
    this.settings.techNumber = this.organizationSettingsCreateForm.controls['techNumber'].value;
    this.organizationSettingsService.setOrganizationSettings(this.settings).subscribe((data) => {
      const response = data as ApiResponse;
      this.notificationService.FromApiResponse(response);
      this.organizationEditMode = false;
    });
    this.settings.timeZone = this.organizationSettingsCreateForm.controls['timeZone'].value;
  }
  async generateToken(){
    this.organizationSettingsService.generateToken().subscribe((data) => {
      const response = data as ApiResponse;
      this.notificationService.FromApiResponse(response);
      this.settings.techToken = data.toString();
    });
  }
  userInRole(role: string[]) {
    return this.authenticationService.userInRole(role);
  }

}
