import { Employee } from "./employee.model";
import { Workday } from "./workday-response.model";

export class ChartWork {
  constructor(
    public id?: number,
    public name?: string,
    public isTwentyFour?: boolean,
  ) { }
}
