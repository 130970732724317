import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UrlConstants } from '../constants/app.url.constants';
import { Settings } from '../models/setting.model';

@Injectable()
export class SettingService {

  constructor(private http: HttpClient,
              @Inject('BASE_URL') protected baseUrl: string) { }

  getSettings(): Observable<Settings> {
    return this.http.get(this.baseUrl + UrlConstants.Settings.All).pipe(
      map((res: Settings) => res)
    );
  }

  updateSettings(settings: Settings) {
    return this.http.put(this.baseUrl + UrlConstants.Settings.Update, settings);
  }
}
