import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { Employee } from 'src/app/models/employee.model';
import { TimesheetRequest } from 'src/app/models/timesheet-request.model';
import { TimesheetResponce } from 'src/app/models/timesheet-responce.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { ReportService } from 'src/app/services/report.service';
import * as XLSX from 'xlsx';
import { MonthFormatPipe } from 'src/app/pipes/month-format.pipe';
import { NgCalendarLocale } from 'src/app/locales/ru.locale';
import { Organization } from 'src/app/models/organization.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { DataTable } from 'primeng/primeng';

@Component({
  selector: 'app-report-organization',
  templateUrl: './report-organization.component.html',
  styleUrls: ['./report-organization.component.css']
})
export class ReportOrganizationComponent implements OnInit {

  calendarLocale = NgCalendarLocale;

  month: Date;
  enableButton = false;
  yearRange = '2000:' + (new Date()).getFullYear().toString();
  organizations: Organization[] = [];



  @ViewChild(Table) timesheetTable: Table;

  constructor(private employeeService: EmployeeService,
              private reportService: ReportService,
              private organizationService: OrganizationService,
              private datepipe: DatePipe,
              private monthFormatPipe: MonthFormatPipe) {}

  ngOnInit() {
    this.loadOrganizations();
  }

  loadOrganizations() {
    this.reportService.getOrganization().subscribe((data: Organization[]) => {
      this.organizations = data;
      this.organizations.forEach((org)=>{
        this.organizationService.getCountEmployees(org.id).subscribe((data: number) =>{ 
          org.countEmployee = data;
        });
        org.lastTimeAuth = this.datepipe.transform(org.lastTimeAuth, 'dd.MM.yyyy HH:mm');
      }
      );
    });

  }

  getOrganization(){
    this.loadOrganizations();
  }
  getCountEmployee(id){
    return this.organizationService.getCountEmployees(id);
  }

  getReportData() {
    let report = [];
    for (let org of this.organizations) {
      var data = {
        'name': org.name,
        'email': org.techEmail,
        'number': org.techNumber,
        'last_user': org.lastUserAuth,
        'last_time_user':  org.lastTimeAuth,
        'count_emploee': org.countEmployee,

      }
      report.push(data);
    }
    return report;
  }
  exportExcel() {
    const worksheet = XLSX.utils.json_to_sheet(this.getReportData());
    worksheet.A1.v = "Название";
    worksheet.B1.v = "Email тех. поддержки";
    worksheet.C1.v = "Номер тех. поддержки";
    worksheet.D1.v = "Последний авторизовавшийся";
    worksheet.E1.v = "Время последней авторизации";
    worksheet.F1.v = "Количество сотрудников";
    worksheet['!cols'] = [
      {wch:10},
      {wch:14},
      {wch:14}
    ];
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    var nowDate =  this.datepipe.transform(new Date(), 'dd.MM.yyyy HH:mm')
    XLSX.writeFile(workbook, 'Отчет по организациям ' + nowDate + '.xlsx');
  }

}
