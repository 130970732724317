import * as CryptoJS from 'crypto-js';

export function decrypt(key, ciphertextB64) {

  var key = CryptoJS.enc.Utf8.parse(key);
  var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);

  var decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
}


export function check_email(email: string) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function check_number(number: string) {
  var ru = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
  var all = /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/;
  if (!ru.test(number))
  {
    return all.test(number);
  }
  return ru.test(number);
}


export function check_double(number: string) {
  var double = /^[-]?[0-9]*[.]?[0-9]+$/;
  return double.test(number);
}