import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/components/common/message';
import { Subject } from 'rxjs';
import { ActionTokenEnum } from 'src/app/enums/action-token.enum';
import { ActionTokenResult } from 'src/app/models/action-token-result.model';
import { ActionToken } from 'src/app/models/action-token.model';
import { ActionTokenService } from 'src/app/services/action-token.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import { SettingService } from '../../services/setting.service';
import { Settings } from '../../models/setting.model';
import { SettingsTypeEnum } from '../../enums/settings-type.enum';
import { decrypt } from 'src/app/utils/encryption.util';

interface Action {
  name: string;
  action: ActionTokenEnum;
}
@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {

  decryptKey = "Z6c$Laaph7~S8}D?";

  actions: Action[];
  selectedAction: Action;

  secondsRemaining: number;
  intervalQR: any;
  msgsTimer: any;
  loading = false;
  actionTokenResult: ActionTokenResult;
  actionToken: ActionToken;
  actionTokenVisible = true;
  firstInit = true;

  backgroundImagePath: string;

  // Notification
  notificationVisible = false;
  notificationMessages: Message[] = [];

  isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  isMaxthon = /maxthon\//i.test(window.navigator.userAgent);

  constructor(private actionTokenService: ActionTokenService,
    private notificationService: NotificationService,
    private signalRService: SignalRService,
    private settingService: SettingService) {
    this.secondsRemaining = 0;

    this.actions = [
      { name: 'Начало рабочего дня', action: ActionTokenEnum.OpenWorkday },
      { name: 'Окончание рабочего дня', action: ActionTokenEnum.CloseWorkday }
    ];
    this.selectedAction = this.actions[0];
  }

  ngOnInit() {
    this.signalRService.onSuccessToken((actionTokenResult: ActionTokenResult) => {
      if (this.actionToken) {
        if (actionTokenResult.id === this.actionToken.id) {
          this.actionToken = null;
          this.secondsRemaining = 0;

          this.showNotification('success', 'Действие выполнено', decrypt(this.decryptKey, actionTokenResult.message));

          this.firstInit = true;
          this.actionTokenResult = actionTokenResult;
        }
      }
    });

    this.signalRService.onErrorToken((actionTokenResult: ActionTokenResult) => {
      if (this.actionToken) {
        if (actionTokenResult.id === this.actionToken.id) {
          this.showNotification('error', 'Ошибка', decrypt(this.decryptKey, actionTokenResult.message));
        }
      }
    })

    this.settingService.getSettings().subscribe((data: Settings) => {
      const backgroundImagePath = data.settings.find(x => x.type === SettingsTypeEnum.Background);
      if (backgroundImagePath) {
        this.backgroundImagePath = backgroundImagePath.value;
      }
    });
  }

  ngOnDestroy(): void {
    this.signalRService.unsubscribeAll();
  }

  actionChange(val: Action): void {
    this.clearActionToken();
  }

  startTimer(): void {
    if (this.intervalQR) {
      clearInterval(this.intervalQR);
    }

    this.intervalQR = setInterval(() => {
      if (this.secondsRemaining === 0 || !this.actionToken) {
        clearInterval(this.intervalQR);
        this.clearActionToken();
      } else {
        this.secondsRemaining--;
      }
    }, 1000)
  }

  getToken(): void {
    this.clearActionToken();
    this.loading = true;
    this.hideNotification();

    this.actionTokenService.getToken(this.selectedAction.action, this.signalRService.getConnectionId()).subscribe((data) => {
      this.loading = false;
      this.firstInit = false;

      this.actionToken = data;

      const utcNow = Date.now();
      this.secondsRemaining = Math.ceil((data.dueDate.getTime() - utcNow) / 1000);
      this.startTimer();
    });
  }

  clearActionToken() {
    this.firstInit = true;
    this.actionToken = null;
    this.actionTokenResult = null;
    this.secondsRemaining = 0;
  }

  hideNotification(): void {
    this.actionTokenVisible = true;
    this.notificationVisible = false;
    this.notificationMessages = [];
  }

  showNotification(severity_: string, summary_: string, detail_: string): void {
    this.actionTokenVisible = false;
    this.notificationVisible = true;
    this.clearActionToken();

    this.notificationMessages = [];
    this.notificationMessages.push({ severity: severity_, summary: summary_, detail: detail_ });
  }
}
