import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstants } from '../constants/app.url.constants';

@Injectable()
export class RoleService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) {
  }

  getRoles() {
    return this.http.get(this.baseUrl + UrlConstants.Role.All);
  }

  
}
