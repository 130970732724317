import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { UrlConstants } from '../constants/app.url.constants';
import { Employee } from '../models/employee.model';
import { TableParams } from '../models/table-params.model';
import { TableResults } from '../models/table-results';
import { NotificationService } from './notification.service';

@Injectable()
export class EmployeeService {

  constructor(private http: HttpClient,
    private notificationService: NotificationService,
    @Inject('BASE_URL') protected baseUrl: string) { }

  getEmployees() {
    return this.http.get(this.baseUrl + UrlConstants.Employees.All).toPromise();
  }

  getEmployeesTable(tableParams: TableParams): Observable<TableResults<Employee>> {
    return this.http.post(this.baseUrl + UrlConstants.Employees.List, tableParams).pipe(
      map((res: TableResults<Employee>) => res));
  }

  getEmployeesExport(): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + UrlConstants.Employees.Export,
      {
        headers: headers,
        responseType: "arraybuffer"
      }
    )
  }

  getEmployee(id: any){
    return this.http.get(this.baseUrl + UrlConstants.Employees.Get.replace(':userId', id));
  }

  setEmployees(employees: Employee[]) {
    return this.http.post(this.baseUrl + UrlConstants.Employees.Import, employees)
  }

  createEmployee(employee: Employee) {
    return this.http.post(this.baseUrl + UrlConstants.Employees.Create, employee);
  }

  updateEmployee(employee: Employee, connectionId: string) {
    var data = {
      "employee": employee,
      "connectionId": connectionId
    }
    return this.http.put(this.baseUrl + UrlConstants.Employees.Update.replace(':userId', employee.id), data);
  }

  updateEmployees(employees: Employee[], connectionId: string) {
    var data = {
      "employees": employees,
      "connectionId": connectionId
    }
    return this.http.post(this.baseUrl + UrlConstants.Employees.UpdateEmployees, data);
  }

  deleteEmployee(id: string) {
    return this.http.delete(this.baseUrl + UrlConstants.Employees.Delete.replace(':userId', id));
  }
}
