import { ChartWork } from "./chart-work.model";
import { ObjectOrganization } from "./objectorganization.model";

export class Employee {
  constructor(
    public id?: string,
    public fio?: string,
    public inn?: string,
    public mobileId?: string,
    public edited?: boolean,
    public organizationId?: string,
    public mobileVersion?: string,
    public chartWork?: ChartWork,
    public chartWorkId?: number,
    public objects?: Array<ObjectOrganization>,
    public objectsName?: Array<string>,
    public select?: boolean,
    public is_del?: boolean,
    public inn_fio?: string,
  ) { }
}
