import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { Injectable } from "@angular/core";

@Injectable()
export class OrganizationsGuard implements CanActivate {

  constructor(private router: Router,
    private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    
    if (this.authenticationService.userInRole(["Admin"])) {
      return true;
    }

    if (this.authenticationService.userInRole(['Manager','ManagerFull'])) {
      this.router.navigate(['/admin/employees']);
      return false;
    }else{
      this.router.navigate(['/admin/errand']);
      return false;
    }


    this.router.navigate(['/forbidden']);
    return false;
  }
}
