import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstants } from '../constants/app.url.constants';
import { ChartWork } from '../models/chart-work.model';

@Injectable()
export class ChartWorkService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) {
  }

  getChartsWork() {
    return this.http.get(this.baseUrl + UrlConstants.ChartWork.All);
  }

  getChartWork(id: string) {
    return this.http.get(this.baseUrl + UrlConstants.ChartWork.Get.replace(':chartworkId', id));
  }

  createChartWork(chartWork: ChartWork) {
    return this.http.post(this.baseUrl + UrlConstants.ChartWork.Create, chartWork);
  }

  updateChartWork(chartWork: ChartWork) {
    return this.http.put(this.baseUrl + UrlConstants.ChartWork.Update.replace(':chartWorkId', chartWork.id.toString()), chartWork);
  }
  deleteChartWork(chartWork: ChartWork) {
    return this.http.delete(this.baseUrl + UrlConstants.ChartWork.Delete.replace(':chartWorkId', chartWork.id.toString()));
  }
}
