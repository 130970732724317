import { Employee } from "./employee.model";
import { Errand } from "./errand.model";

export class ErrandEmployee{
  constructor(
    public id?: string,
    public status?: string,
    public errandId?: string,
    public employeeId?: string,
    public errand?: Errand,
    public employee?: Employee,
    public startErrand?: string,
    public closeErrand?: string,
  ) { }
}
