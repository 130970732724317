import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsTypeEnum } from 'src/app/enums/settings-type.enum';
import { Setting, Settings } from 'src/app/models/setting.model';
import { TimeZone } from 'src/app/models/time-zone.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingService } from 'src/app/services/setting.service';
import { TimeZoneService } from 'src/app/services/time-zone.service';
import { ApiStatusEnum } from '../../../../enums/api-status.enum';
import { ApiResponse } from '../../../../models/api-response.model';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  settungsType = SettingsTypeEnum;
  settings: Array<Setting>;
  timeZones: any;
  selectedTimeZone: TimeZone;
  submitClick = false;

  backgroundImageFullPath: string;        // путь к картинке фонового изображения
  showFullBackgroundImageModal = false;   // флаг отображения модального окна с фоновой картинкой
  showPreviewButtons = false;             // флаг отображения кнопок на превьшке фоновой картинки

  settings_form: FormGroup;

  constructor(private settingService: SettingService,
              private timeZoneService: TimeZoneService,
              private notificationService: NotificationService) {

      this.settings_form = new FormGroup({
        actionTokenDuration: new FormControl('', [Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
          Validators.minLength(1),
          Validators.maxLength(5)]),
      });
    }

  async ngOnInit() {
    this.timeZones = await this.timeZoneService.getTimeZones();

    this.loadSettings();
  }

  loadSettings() {
    this.settingService.getSettings().subscribe((data: Settings) => {
      this.settings = data.settings;
      
      this.settings_form.setValue({
        actionTokenDuration: this.settings.find(x=> x.type === SettingsTypeEnum.ActionTokenDuration).value
      });

      var timezone = this.settings.find(x => x.type == SettingsTypeEnum.TimeZone);
      if (timezone){
        this.selectedTimeZone = this.timeZones.find(x => x.id === timezone.value);
      }

      this.backgroundImageFullPath = this.settings.find(x => x.type === SettingsTypeEnum.Background).value;
    });
  }

  saveSettings() {
    if (this.settings_form.valid) {
      this.submitClick = true;

      var duration = this.settings.find(x => x.type == SettingsTypeEnum.ActionTokenDuration);
      if (duration) {
        duration.value = this.settings_form.controls['actionTokenDuration'].value;
      }

      var timezone = this.settings.find(x => x.type == SettingsTypeEnum.TimeZone);
      if (timezone) {
        timezone.value = this.selectedTimeZone.id;
      }

      var backgroundImage = this.settings.find(x => x.type == SettingsTypeEnum.Background);
      if (backgroundImage) {
        backgroundImage.value = this.backgroundImageFullPath;
      }

      this.settingService.updateSettings(new Settings(this.settings)).subscribe((data) => {
        this.submitClick = false;

        this.notificationService.FromApiResponse(data);
      });
    }
  }

  getSettingsDescription(type : SettingsTypeEnum){
    switch(type){
      case SettingsTypeEnum.ActionTokenDuration: {
        return "Время жизни QR-кода, сек";
      }
      case SettingsTypeEnum.TimeZone: {
        return "Часовой пояс";
      }
      case SettingsTypeEnum.Background: {
        return "Фоновое изображение страницы сотрудника";
      }
    }
  }

  // обработчик завершения загрузки фонового изображения
  onBackgroundImageUploadComplete(event) {
    const response = event.originalEvent.body as ApiResponse;
    if (response.statusType === ApiStatusEnum.Success) {
      this.backgroundImageFullPath = response.body;
    }
  }

  showFullImage() {
    this.showFullBackgroundImageModal = true;
  }

  removeBackgroundImage() {
    this.backgroundImageFullPath = null;
  }

  onImageWrapperMouseEnter() {
    this.showPreviewButtons = true;
  }

  onImageWrapperMouseLeave() {
    this.showPreviewButtons = false;
  }

  validateFileSize(event: any, maxFileSize: number) {
    if (event.files[0].size > maxFileSize) {
      this.notificationService.Error("Файл слишком большой, максимальный размер - 1 Мб");
    }
  }
}
