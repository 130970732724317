import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { Employee } from 'src/app/models/employee.model';
import { TimesheetRequest } from 'src/app/models/timesheet-request.model';
import { TimesheetResponce } from 'src/app/models/timesheet-responce.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { ReportService } from 'src/app/services/report.service';
import * as XLSX from 'xlsx';
import { MonthFormatPipe } from 'src/app/pipes/month-format.pipe';
import { NgCalendarLocale } from 'src/app/locales/ru.locale';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent implements OnInit {

  calendarLocale = NgCalendarLocale;

  month: Date;
  enableButton = false;
  yearRange = '2000:' + (new Date()).getFullYear().toString();
  employees: Employee[] = [];
  selectedEmployee: Employee;

  timesheet: TimesheetResponce;
  workdayLink: string;

  @ViewChild(Table) timesheetTable: Table;

  constructor(private employeeService: EmployeeService,
              private reportService: ReportService,
              private datepipe: DatePipe,
              private monthFormatPipe: MonthFormatPipe) {}

  ngOnInit() {
    const all = new Employee(null, "Все", null, null);
    this.employees.push(all);
    this.selectedEmployee = this.employees[0];

    this.loadEmployees();
  }

  loadEmployees() {
    this.employeeService.getEmployees().then((data: Employee[]) => {
      this.employees = this.employees.concat(data)
    });
  }

  onCalendarSelect(){
    this.doToggleButton(true);
  }

  doToggleButton(status: boolean){
    this.enableButton = status;
  }

  getTimesheet(){
    this.doToggleButton(false);

    let request = new TimesheetRequest(this.month, this.selectedEmployee.id ? this.selectedEmployee : null);
    this.reportService.getTimesheet(request).subscribe((data)=> {
      this.timesheet = data;
      this.workdayLink = this.initWorkdayLink();
    });

    this.doToggleButton(true);
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.timesheetTable.el.nativeElement);
    // Adding header with month to xls
    var aoa = XLSX.utils.sheet_to_json<any>(ws, { header: 1 });
    aoa.unshift([this.monthFormatPipe.transform(this.month) + ' ' + this.datepipe.transform(this.month, 'yyyy')]);
    var sheet = XLSX.utils.aoa_to_sheet(aoa);
    sheet['!merges'] = [
      { s: { c: 0, r: 0 }, e: { c: aoa[1].length - 1, r: 0 } }
    ];

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, 'Sheet1');
    XLSX.writeFile(wb, this.datepipe.transform(this.month, 'MM-yyyy') + ' ' + this.selectedEmployee.fio + '.xlsx');
  }

  getWorkdayReportLink(id: string){
    return this.workdayLink + id;
  }

  initWorkdayLink(){
    var result = '/admin/reports/workday?start=';
    result = result + this.datepipe.transform(this.month, 'MM/dd/yyyy');

    var newDate = this.datepipe.transform(this.getEndDate(this.month), 'MM/dd/yyyy');

    result = result + '&end=' + newDate;
    result = result + '&employee=';

    return result;
  }

  getEndDate(date: Date) {
    // Create new date object, because date is passed by reference
    var localDate = new Date(date);

    var nextMonth = new Date(localDate.setMonth(localDate.getMonth() + 1));
    return nextMonth.setDate(nextMonth.getDate() - 1);
  }
}
