import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { UrlConstants } from '../constants/app.url.constants';
import { Administrator } from '../models/administrator.model';
import { TableParams } from '../models/table-params.model';
import { TableResults } from '../models/table-results';

@Injectable()
export class AdministratorService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) { }

  getAdministratorTable(tableParams: TableParams): Observable<TableResults<Administrator>> {
    return this.http.post(this.baseUrl + UrlConstants.Administrators.List, tableParams).pipe(
      map((res: TableResults<Administrator>) => res));
  }

  getAdminstrator() {
    return this.http.get(this.baseUrl + UrlConstants.Administrators.All);
  }

  createAdministrator(admin: Administrator) {
    return this.http.post(this.baseUrl + UrlConstants.Administrators.Create, admin);
  }

  deleteAdministrator(id: string) {
    return this.http.delete(this.baseUrl + UrlConstants.Administrators.Delete.replace(':userId', id));
  }
}
