import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/models/api-response.model';
import { ManagerRequest } from 'src/app/models/manager-request.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ManagerService } from 'src/app/services/manager.service';
import { NotificationService } from 'src/app/services/notification.service';
import { EmployeesComponent } from '../employees/employees.component';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

  public userName;
  public userOrganization;
  editFlag = false;
  passCreateForm: FormGroup; 

  constructor(private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private managerService: ManagerService) { }

  ngOnInit() {
    this.getUserName();
    this.authenticationService.roleChange.subscribe(() => {
      this.getUserName();
    });
    this.passCreateForm = new FormGroup({
      new_password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required]),
    });
  }


  getUserName() {
    this.userName = this.authenticationService.userName;
    this.userOrganization = this.authenticationService.organizationName;
    
  }
  editPassword() {
    this.userName = this.authenticationService.userName;
    this.userOrganization = this.authenticationService.organizationName;
    
  }
  check_value(){

    if (!this.passCreateForm.controls['new_password'].valid) {
      this.notificationService.Error("Заполните новый пароль");
      return;
    }  
    if (!this.passCreateForm.controls['confirm_password'].valid) {
      this.notificationService.Error("Заполните подтверждение пароля");
      return;
    }
    if (this.passCreateForm.controls['confirm_password'].value !== this.passCreateForm.controls['new_password'].value) {
      this.notificationService.Error("Пароли не совпадают");
      return;
    }
    return true;
  }
  cancel() {
    this.passCreateForm.reset();
    this.editFlag = false;
  }
  save() {
    if (!this.check_value()) {
      return;
    }
    var new_password = this.passCreateForm.controls['new_password'].value;
    var new_data = new ManagerRequest('',new_password,{},{});
    this.managerService.updateManager(new_data).subscribe(data=>{
      const error = data as ApiResponse;
      this.notificationService.FromApiResponse(error);
    });
    this.passCreateForm.reset();
    this.editFlag = false;
  }
  userInRole(role: string[]) {
    return this.authenticationService.userInRole(role);
  }
}
