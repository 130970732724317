import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { ApiStatusEnum } from 'src/app/enums/api-status.enum';
import { TableParams } from 'src/app/models/table-params.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';

import { Administrator } from '../../../models/administrator.model';
import { ApiResponse } from '../../../models/api-response.model';
import { AdministratorService } from '../../../services/administrator.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css'],
  providers: [AdministratorService]
})
export class AdministrationComponent implements OnInit {

  admin: Administrator = new Administrator();
  admins: Administrator[];
  adminForm: FormGroup;
  adminsCount = 0;

  filterValue='';

  createMode: boolean = false;

  public loadingTable = true;
  public rows = 10;
  public initTableParams: TableParams = null;
  public lastAdminParams: TableParams = new TableParams();

  @ViewChild(Table) adminTable: Table;

  backgroundImageUploadInProgess = false;                     // флаг определения выполнения процесса загрузки фонового изображения

  constructor(private administratorService: AdministratorService,
              private notificationService: NotificationService,
              private authentificationService: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.adminForm = new FormGroup({
      userName: new FormControl(this.admin.userName, [
        Validators.required
      ]),
      password: new FormControl(this.admin.password, [
        Validators.required
      ]),
      confirmPassword: new FormControl(this.admin.confirmPassword)
    });
    this.adminForm.setValidators(this.passwordMatchValidator());
  }

  passwordMatchValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      let pass = formGroup.get('password').value;
      let confirmPass = formGroup.get('confirmPassword').value;
      if (pass !== confirmPass) {
        formGroup.controls['confirmPassword'].setErrors({ notMatch: true });
      } else {
        formGroup.controls['confirmPassword'].setErrors(null);
      }
      return;
    }
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams) {
      var temp = new TableParams(this.activatedRoute.snapshot.queryParams);

      if (temp instanceof TableParams) {
        this.initTableParams = temp;
        this.filterValue = this.initTableParams.globalFilter;
        this.rows = temp.rows;

        this.adminTable.first = temp.first;
        this.adminTable.sortField =  temp.sortField;
        this.adminTable.sortOrder = temp.sortOrder;
      }
    }
  }

  onChangeFilter(event){
    this.adminTable.filterGlobal(event, 'contains');
  }

  loadAdmins(params?) {
    if (this.initTableParams){
      params = this.initTableParams;
      this.initTableParams = null;
    }

    let tableParams = new TableParams(params, this.filterValue);
    if (params) {
      this.loadingTable = true;
      this.lastAdminParams = tableParams;
    } else {
      tableParams = this.lastAdminParams;
    }

    let navigationExtras: NavigationExtras = {
      queryParams: tableParams
    };

    this.router.navigate([], navigationExtras);

    this.administratorService.getAdministratorTable(tableParams).subscribe(
      result => {
        this.admins = result.data;
        this.adminsCount = result.count;

        this.loadingTable = false;
      }, error => {
        this.loadingTable = false;
      }
    );
  }

  save() {
    if (!this.adminForm.controls['userName'].valid) {
      this.notificationService.Error("Заполните логин");
      return;
    }

    if (!this.adminForm.controls['password'].valid) {
      this.notificationService.Error("Заполните пароль");
      return;
    }

    if (this.adminForm.controls['confirmPassword'].hasError('notMatch')) {
      this.notificationService.Error("Пароли не совпадают");
      return;
    }

    this.admin.userName = this.adminForm.controls['userName'].value;
    this.admin.password = this.adminForm.controls['password'].value;

    if (this.admin.id == null) {
      this.administratorService.createAdministrator(this.admin).subscribe((data) => {
        let error = data as ApiResponse;
        this.notificationService.FromApiResponse(error);

        if (error.statusType == ApiStatusEnum.Success) {
          this.loadAdmins();
          this.cancel();
        }
      });
    }
  }

  cancel() {
    this.admin = new Administrator();
    this.createMode = false;
    this.adminForm.reset();
  }

  add() {
    this.cancel();
    this.createMode = true;
  }

  removeAdmin(admin: Administrator) {
    this.notificationService.Confirm(
      'Удаление администратора',
      'Вы действительно хотите удалить администратора ' + admin.userName + '?',
      async () => this.administratorService.deleteAdministrator(admin.id).subscribe((data) => {
        if (admin.id == this.authentificationService.userId) {
          this.authentificationService.logout();
          this.router.navigate(['/login']);
        } else {
          this.loadAdmins();
        }

        this.notificationService.FromApiResponse(data);
      }));
  }
}
