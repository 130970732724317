import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';

import { ApiResponse } from '../../../models/api-response.model';
import { NotificationService } from 'src/app/services/notification.service';

import { ObjectOrganization } from '../../../models/objectorganization.model'
import { ObjectOrganizationService } from '../../../services/objectorganization.service';
import { ApiStatusEnum } from '../../../enums/api-status.enum';
import { AuthenticationService } from '../../../services/authentication.service';
import { FormGroup, Validators, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { OrganizationService } from 'src/app/services/organization.service';
import { Organization } from 'src/app/models/organization.model';
import { ManagerService } from 'src/app/services/manager.service';
import { Manager } from 'src/app/models/managers-view.model';
import { check_double } from 'src/app/utils/encryption.util';

@Component({
  selector: 'app-objectorganizations',
  templateUrl: './objectorganizations.component.html',
  styleUrls: ['./objectorganizations.component.css'],
  providers: [ObjectOrganizationService, OrganizationService]
})



export class ObjectOrganizationsComponent implements OnInit {

  organization: Organization = null;
  objectOrganization: ObjectOrganization = new ObjectOrganization();  // изменяемый объект организация
  objectOrganizations: ObjectOrganization[] = [];               // массив объектов организаций
  objectOrganizationsCount = 0;                           // количество объектов организаций для загрузки

  objectOrganizationCreateMode = false;                   // режим создания нового объекта организации
  objectOrganizationCreateForm: FormGroup;                // форма для создания объекта организации
  objectOrganizationCreateInProgress = false;             // флаг для определения выполнения процедуры создания объекта организации

  objectOrganizationEditMode = false;                     // режим редактирования объекта организации
  objectOrganizationEditForm: FormGroup;                  // форма для редактирования объекта организации
  objectOrganizationEditInProgress = false;               // флаг для определения выполнения процедуры редактирования объекта организации

  loadingObjectOrganization = false;                          // индикатор загрузки данных об объекте

  public userRoles;

  public loadingTable = true;                       // индикатор включения загрузки в таблице


  constructor(private objectOrganizationService: ObjectOrganizationService,
              private organizationService: OrganizationService,
              private notificationService: NotificationService,
              private authenticationService: AuthenticationService,
              private managerService: ManagerService) { }

  ngOnInit() {
    this.userRoles = this.authenticationService.roles;
    this.authenticationService.roleChange.subscribe(() => {
      this.userRoles = this.authenticationService.roles;
    });
    
    this.managerService.getOrganizationsByManagerId(this.authenticationService.userId).subscribe((data:Organization) => {
      this.organization = data[0];
      this.loadObjectOrganizations(this.organization.id);
    });
    

    this.objectOrganizationCreateForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      organizationId: new FormControl(this.objectOrganization.organizationId, [Validators.required]),
      latitude: new FormControl(0.0035, [Validators.required]),
      longitude: new FormControl(0.0035, [Validators.required]),
      accuracyError: new FormControl(0.0035, [Validators.required]),
    });
  }

  // Получение данных организаций
  loadObjectOrganizations(id) {
    this.loadingObjectOrganization = true;
    this.objectOrganizationService.getObjectOrganizations()
      .subscribe((data: ObjectOrganization[]) => {
        this.objectOrganizations = data.filter(e => e.organizationId == id);
        this.objectOrganizations.forEach(el => {
        el.organizationName = this.organization.name;
        })
        this.loadingTable = false;
        this.loadingObjectOrganization = false;
      },
      error => { this.loadingObjectOrganization = false; });
    
  }

  check_value(){
    if (!this.objectOrganizationCreateForm.controls['name'].valid) {
      this.notificationService.Error("Заполните название");
      return;
    }
    if (!check_double(this.objectOrganizationCreateForm.controls['latitude'].value)) {
      this.notificationService.Error("Неккоректное число широты. Используйте '.' ");
      return;
    }
    if (!check_double(this.objectOrganizationCreateForm.controls['longitude'].value)) {
      this.notificationService.Error("Неккоректное число долготы. Используйте '.' ");
      return;
    }
    if (!check_double(this.objectOrganizationCreateForm.controls['accuracyError'].value)) {
      this.notificationService.Error("Неккоректное число погрешности. Используйте '.' ");
      return;
    }
    return true;
  }
  // Сохранение данных объекта организации
  save() {
    if (!this.check_value()) {
      return;
    }
    
    this.objectOrganization.name = this.objectOrganizationCreateForm.controls['name'].value;
    this.objectOrganization.latitude = this.objectOrganizationCreateForm.controls['latitude'].value;
    this.objectOrganization.longitude = this.objectOrganizationCreateForm.controls['longitude'].value;
    this.objectOrganization.accuracyError = this.objectOrganizationCreateForm.controls['accuracyError'].value;
    this.objectOrganization.organizationId = this.organization.id;
    if (this.objectOrganization.id == null) {
      this.objectOrganizationCreateInProgress = true;
      this.objectOrganizationService.createObjectOrganization(this.objectOrganization)
        .subscribe((data) => {
          const error = data as ApiResponse;

          this.notificationService.FromApiResponse(error);

          if (error.statusType == ApiStatusEnum.Success) {
            this.loadObjectOrganizations(this.organization.id);
            this.hideObjectOrganizationCreateDialog();
          }
          this.objectOrganizationCreateInProgress = false;
        });
    } else {
      this.objectOrganizationCreateInProgress = true;
      this.objectOrganizationService.updateObjectOrganization(this.objectOrganization)
        .subscribe(data => {
          this.notificationService.FromApiResponse(data);
          this.loadObjectOrganizations(this.organization.id);
          this.hideObjectOrganizationEditDialog();
          this.objectOrganizationEditInProgress = false;
        });
    }
  }

  //
  editObjectOrganization(organization: ObjectOrganization) {
    this.objectOrganization = organization;
  }

  // Удаление объекта организации
  delete(objectOrganization: ObjectOrganization) {
    this.notificationService.Confirm(
      'Удаление объекта организации',
      'Вы действительно хотите удалить объект ' + objectOrganization.name + '?',
      async () => this.objectOrganizationService.deleteObjectOrganization(objectOrganization.id).subscribe((data) => {
        this.notificationService.FromApiResponse(data);
        this.loadObjectOrganizations(this.organization.id);
        this.hideObjectOrganizationCreateDialog();
        this.objectOrganization = new ObjectOrganization();
      }));
  }

  // Вход в режим создания организации
  add() {
    this.objectOrganization = new ObjectOrganization();
    this.hideObjectOrganizationCreateDialog();
    this.objectOrganizationCreateMode = true;
  }

  // Выход из режима создания организации
  hideObjectOrganizationCreateDialog() {
    this.objectOrganizationCreateMode = false;
    this.objectOrganizationCreateForm.reset();
    this.objectOrganizationCreateForm.setValue({
      name: '',
      organizationId: '',
      latitude: 0.0035,
      longitude: 0.0035,
      accuracyError: 0.0035,

    });
  }

  // Обработчик открытия вкладки с организацией
  onDropdownChange(event) {
    this.objectOrganization = event.value;
    // this.getManagersByOrganization(event.value);
  }


  // Отображение окна переименования организации
  showObjectOrganizationEditDialog(objectOrganization: ObjectOrganization) {
    this.objectOrganization = objectOrganization;
    this.objectOrganizationCreateForm.setValue({
      name: objectOrganization.name,
      organizationId: objectOrganization.organizationId,
      latitude: objectOrganization.latitude,
      longitude: objectOrganization.longitude,
      accuracyError: objectOrganization.accuracyError,

    });
    this.objectOrganizationEditMode = true;
  }

  // Скрытие окна переименования организации
  hideObjectOrganizationEditDialog() {
    this.objectOrganizationEditMode = false;
    this.objectOrganizationCreateForm.reset();
  }
}
