import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';

import { ApiResponse } from '../../../models/api-response.model';
import { NotificationService } from 'src/app/services/notification.service';

import { Organization } from '../../../models/organization.model'
import { OrganizationService } from '../../../services/organization.service';
import { ApiStatusEnum } from '../../../enums/api-status.enum';
import { Manager, ManagersView } from '../../../models/managers-view.model';
import { ManagerService } from '../../../services/manager.service';
import { ManagerRequest } from '../../../models/manager-request.model';
import { AuthenticationService } from '../../../services/authentication.service';
import { FormGroup, Validators, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { SettingsTypeEnum } from 'src/app/enums/settings-type.enum';
import { check_email, check_number } from 'src/app/utils/encryption.util';
import { TimeZoneService } from 'src/app/services/time-zone.service';
import { Role } from 'src/app/models/role.model';
import { RoleService } from 'src/app/services/role.service';
import { RoleUpdate } from 'src/app/models/role-update.model';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css'],
  providers: [OrganizationService, ManagerService, RoleService]
})
export class OrganizationsComponent implements OnInit {

  organization: Organization = new Organization();  // изменяемая организация
  organizations: Organization[] = [];               // массив организаций
  organizationsCount = 0;                           // количество организаций для загрузки
  rolesOrganization: Role[] = [];

  organizationCreateMode = false;                   // режим создания новой организации
  organizationCreateForm: FormGroup;                // форма для создания организации
  organizationCreateInProgress = false;             // флаг для определения выполнения процедуры создания организации

  organizationEditMode = false;                     // режим редактирования организации
  organizationEditForm: FormGroup;                  // форма для редактирования организации
  organizationEditInProgress = false;               // флаг для определения выполнения процедуры редактирования организации

  managerCreateMode = false;                        // режим создания нового менеджера
  managerCreateForm: FormGroup;                     // форма для создания менеджера
  managerCreateInProgress = false;                  // флаг для определения выполнения процедуры создания менеджера
  managerEditMode = false;    
  manager: Manager = new Manager();                 // создаваемый менеджер
  managers: Manager[];                              // массив менеджеров выбранной организации
  
  loadingManagers = false;                          // индикатор загрузки данных о менеджерах

  public userRoles;
  countEmployees: number;                           // количетсво сотрудников
  lastTimeAuth: string ;                            // время последней авторизации
  public loadingTable = true;                       // индикатор включения загрузки в таблице
  newRoleManager: RoleUpdate = new RoleUpdate();
  @ViewChild(Table) managerTable: Table;
  flagChoice: string = '';                               // флаг для выбора между менеджерами и объектами

  constructor(private organizationService: OrganizationService,
              private managerService: ManagerService,
              private notificationService: NotificationService,
              private authenticationService: AuthenticationService,
              private timeZoneService: TimeZoneService,
              private roleServices: RoleService) { }

  ngOnInit() {
    this.userRoles = this.authenticationService.roles;
    this.authenticationService.roleChange.subscribe(() => {
      this.userRoles = this.authenticationService.roles;
    });
    
    this.loadOrganizations();

    this.organizationCreateForm = new FormGroup({
      name: new FormControl(this.organization.name, [Validators.required]),
      techEmail: new FormControl(this.organization.techEmail),
      techNumber: new FormControl(this.organization.techNumber),
    });

    this.managerCreateForm = new FormGroup({
      login: new FormControl(this.manager.login, [Validators.required]),
      role: new FormControl({}, [Validators.required]),
      password: new FormControl(this.manager.password, [Validators.required]),
      confirmPassword: new FormControl(this.manager.confirmPassword)
    });
    this.managerCreateForm.setValidators(this.passwordMatchValidator());
  }

  passwordMatchValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const pass = formGroup.get('password').value;
      const confirmPass = formGroup.get('confirmPassword').value;
      if (pass !== confirmPass) {
        formGroup.controls['confirmPassword'].setErrors({ notMatch: true });
      } else {
        formGroup.controls['confirmPassword'].setErrors(null);
      }
      return;
    }
  }

  // Получение данных организаций
  loadOrganizations() {
    this.organizationService.getOrganizations()
      .subscribe((data: Organization[]) => {
        this.organizations = data;
        this.loadingTable = false;
      });
    this.roleServices.getRoles()
      .subscribe((data: Role[]) => {
        this.rolesOrganization = data;       
    });
  }

  // Сохранение данных организации
  save() {
    if (!this.organizationCreateForm.controls['name'].valid) {
      this.notificationService.Error("Заполните название");
      return;
    }
    if (this.organizationCreateForm.controls['techEmail'].value && !check_email(this.organizationCreateForm.controls['techEmail'].value)) {
      this.notificationService.Error("Неверный email");
      return;
    }
    if (this.organizationCreateForm.controls['techNumber'].value && !check_number(this.organizationCreateForm.controls['techNumber'].value)) {
      this.notificationService.Error("Неверный email");
      return;
    }

    this.organization.name = this.organizationCreateForm.controls['name'].value;
    this.organization.techEmail = this.organizationCreateForm.controls['techEmail'].value;
    this.organization.techNumber = this.organizationCreateForm.controls['techNumber'].value;

    if (this.organization.id == null) {
      this.organizationCreateInProgress = true;
      this.organizationService.createOrganization(this.organization)
        .subscribe((data) => {
          const error = data as ApiResponse;

          this.notificationService.FromApiResponse(error);

          if (error.statusType == ApiStatusEnum.Success) {
            this.loadOrganizations();
            this.hideOrganizationCreateDialog();
          }
          this.organizationCreateInProgress = false;
        });
    } else {
      this.organizationCreateInProgress = true;
      this.organizationService.updateOrganization(this.organization)
        .subscribe(data => {
          this.notificationService.FromApiResponse(data);
          this.loadOrganizations();
          this.hideOrganizationCreateDialog();
          this.organizationCreateInProgress = false;
          this.hideOrganizationEditDialog();
        });
    }
  }

  //
  editOrganization(organization: Organization) {
    this.organization = organization;
  }

  // Удаление организации
  delete(organization: Organization) {
    this.notificationService.Confirm(
      'Удаление организации',
      'Вы действительно хотите удалить организацию ' + organization.name + '? Также будут удалены все сотрудники организации и их данные.',
      async () => this.organizationService.deleteOrganization(organization.id).subscribe((data) => {
        this.notificationService.FromApiResponse(data);
        this.loadOrganizations();
        this.hideOrganizationCreateDialog();
        this.organization = new Organization();
      }));
  }

  // Вход в режим создания организации
  add() {
    this.organization = new Organization();
    this.hideOrganizationCreateDialog();
    this.organizationCreateMode = true;
  }

  // Выход из режима создания организации
  hideOrganizationCreateDialog() {
    this.organizationCreateMode = false;
    this.organizationCreateForm.reset();
  }

  // Получение списка менеджеров для организации
  getManagersByOrganization(organization: Organization) {
    this.loadingManagers = true;
    this.managerService.getManagersByOrganization(organization)
      .subscribe((data: ManagersView) => {
        this.managers = data.managers       
        this.loadingManagers = false;
      },
        error => { this.loadingManagers = false; });
  }
  
  // Добавление менеджера для организации
  addManager() {
    if (!this.managerCreateForm.controls['login'].valid) {
      this.notificationService.Error("Заполните логин");
      return;
    }
    if (!this.managerCreateForm.controls['role'].valid) {
      this.notificationService.Error("Выберите роль");
      return;
    }
    if (!this.managerCreateForm.controls['password'].valid) {
      this.notificationService.Error("Заполните пароль");
      return;
    }

    if (this.managerCreateForm.controls['confirmPassword'].hasError('notMatch')) {
      this.notificationService.Error("Пароли не совпадают");
      return;
    }

    const login = this.managerCreateForm.controls['login'].value;
    const role = this.managerCreateForm.controls['role'].value;
    const password = this.managerCreateForm.controls['password'].value;

    const request = new ManagerRequest(login, password, this.organization, role);
    this.managerService.createManager(request)
      .subscribe(data => {
        const error = data as ApiResponse;
        this.notificationService.FromApiResponse(error);
        this.getManagersByOrganization(this.organization);
        this.hideManagerCreateDialog();
      });
  }

  // Удаление менеджера из организации
  deleteManager(manager: Manager) {
    this.notificationService.Confirm(
      'Удаление менеджера',
      'Вы действительно хотите удалить менеджера ' + manager.login + '?',
      async () => this.managerService.deleteManager(manager.id).subscribe((data) => {
        this.notificationService.FromApiResponse(data);
        this.getManagersByOrganization(this.organization);
      }));
  }

  // Обработчик открытия вкладки с организацией
  async onDropdownChange(event) {
    this.organization = event.value;
    var timeZones;
    await this.timeZoneService.getTimeZones().then(data => {
      timeZones = data.find(x => x.id === this.organization.timeZone);
    });
    this.lastTimeAuth = (new Date(this.organization.lastTimeAuth)).toISOString().slice(0, 19).replace(/-/g, "-").replace("T", " ") + " "+ timeZones['name'];
    this.flagChoice = 'organization';
    this.getManagersByOrganization(event.value);
    this.organizationService.getCountEmployees(this.organization.id).subscribe((data: number) => {
      this.countEmployees = data;
    });
  }

  // Отображение диалога создания менеджера
  showManagerCreateDialog() {
    this.managerCreateMode = true;
  }

  // Скрытие диалога создания менеджера
  hideManagerCreateDialog() {
    this.manager = new Manager();
    this.managerCreateMode = false;
    this.managerCreateForm.reset();
  }

  // Отображение окна переименования организации
  showOrganizationEditDialog() {
    this.organizationCreateForm.setValue({
      name: this.organization.name,
      techEmail: this.organization.techEmail,
      techNumber: this.organization.techNumber
    });
    this.organizationEditMode = true;
  }

  showManagerEditDialog() {
    this.managerCreateForm.setValue({
      login: '',
      role: this.manager.role,
      password: '',
      confirmPassword: ''
    });
    this.managerEditMode = true;
  }
  edit(manager: Manager){
    this.manager = manager;
    this.showManagerEditDialog();
  }
  saveManagerRole(){
    console.log(this.manager);
    console.log(this.managerCreateForm.controls['role'].value);
    this.newRoleManager.id = this.manager.id;
    this.newRoleManager.roleId = this.managerCreateForm.controls['role'].value.id;
    this.managerService.updateRoleManager(this.newRoleManager)
      .subscribe(data => {
        const error = data as ApiResponse;
        this.notificationService.FromApiResponse(error);
        this.getManagersByOrganization(this.organization);
        this.hideManagerEditDialog();
      });
  }
  // Скрытие окна переименования организации
  hideOrganizationEditDialog() {
    this.organizationEditMode = false;
    this.organizationCreateForm.reset();
  }
  // Скрытие окна редактирования менеджера
  hideManagerEditDialog() {
    this.managerEditMode = false;
    this.manager = null;
  }
}
