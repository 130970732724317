export class TableParams {
  first: number;
  rows: number;
  sortField: string;
  globalFilter: string;
  sortOrder: number;

  constructor(params?, filterValue?) {

    if (params && params.first != null) {
      this.first = +params.first;
    } else {
      this.first = 0;
    }

    if (params && params.rows != null) {
      this.rows = +params.rows;
    } else {
      this.rows = 10;
    }

    if (params && params.sortField != null) {
      this.sortField = params.sortField;
    } else {
      this.sortField = '';
    }

    if (params && params.sortOrder != null) {
      this.sortOrder = +params.sortOrder;
    } else {
      this.sortOrder = 0;
    }

    if (params && params.globalFilter != null) {
      this.globalFilter = params.globalFilter;
    } else {
      this.globalFilter = filterValue != null ? filterValue : '';
    }
  }
}
