import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { UrlConstants } from '../constants/app.url.constants';

@Injectable()
export class AppService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) { }

  getVersion() {
    return this.http.get(this.baseUrl + UrlConstants.App.Version);
  }

}
