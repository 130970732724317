import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "MonthFormat"
})
export class MonthFormatPipe implements PipeTransform {
  transform(value: Date, ...args: any[]) {
      var monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
      return monthNames[value.getMonth()]
    }
}
