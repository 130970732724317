import { SettingsTypeEnum } from '../enums/settings-type.enum';

export class Settings{
  constructor(
    public settings: Array<Setting>
  ) { }
}
export class Setting {
  constructor(
    public id?: string,
    public type?: SettingsTypeEnum,
    public value?: string
  ) { }
}
