import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router,
    private authService: AuthenticationService) { }
  intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header to request

    if (request.method === 'GET') {
      request = request.clone({
        setHeaders: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });
    }

    // This convert UTC Date to local Date
    if (request.method === 'POST' || request.method === 'PUT') {
      this.shiftDates(request.body);
    }

    // Get Token data from local storage
    const tokenInfo = JSON.parse(localStorage.getItem('TokenInfo'));

    if (tokenInfo && tokenInfo.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${tokenInfo.token}`,

          // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      });
    }

    const observable = new Observable<HttpEvent<any>>((subscriber) => {
      const originalRequestSubscription = newRequest.handle(request)
        .subscribe((response) => {
          subscriber.next(response);
        },
          (err) => {
            if (this.isInvalidToken(err)) {
              this.router.navigate(['/login']);
            } else {
              subscriber.error(err);
            }
          },
          () => {
            subscriber.complete();
          });

      return () => {
        originalRequestSubscription.unsubscribe();
      };
    });

    return observable;
  }

  isInvalidToken(response: any) {
    return response.status === 401 && this.router.url !== '/login';
  }

  shiftDates(body) {
    if (body === null || body === undefined) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes()
          , value.getSeconds()));
      } else if (typeof value === 'object') {
        this.shiftDates(value);
      }
    }
  }

}
