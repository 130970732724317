import { Organization } from "./organization.model";
import { Role } from "./role.model";

export class ManagerRequest {
  constructor(
    public login: string,
    public password: string,
    public organization: Organization,
    public role: Role
  ) { }
}
