import { TimeZone } from "./time-zone.model";

export class OrganizationSettings {
  constructor(
    public timeZone?: TimeZone,
    public techEmail?: string,
    public techNumber?: string,
    public techToken?: string,
  ) { }
}
