export class Organization {
  constructor(
    public id?: string,
    public name?: string,
    public timeZone?: string,
    public techEmail?: string,
    public techNumber?: string,
    public lastUserAuth?: string,
    public lastTimeAuth?: string,
    public countEmployee?: number,
  ) { }
}
