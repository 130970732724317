import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Output } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UrlConstants } from '../constants/app.url.constants';
import { Organization } from '../models/organization.model';
//import { ManagerService } from './manager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  @Output() public userId: string;
  @Output() public userName: string;
  @Output() public roles: Array<string>;
  @Output() public organizationName: string;

  public roleChange: Subject<Array<string>> = new Subject<Array<string>>();

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    const token = localStorage.getItem('TokenInfo');
    this.setTokenInfo(token);
  }

  login(username: string, password: string) {
    return this.http.post<any>(this.baseUrl + UrlConstants.Auth.Login, { username, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            const token = JSON.stringify(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('TokenInfo', token);

            this.setTokenInfo(token);
          }

          return user;
        }),
        catchError(
          e => {
            return throwError(e);
          }
        )
      );
  }

  logout() {
    this.http.post(this.baseUrl + UrlConstants.Auth.Logout, null)
      .pipe(
        catchError(e => e)
      ).toPromise();

    // remove user from local storage to log user out
    localStorage.removeItem('TokenInfo');
    this.userName = null;
    this.userId = null;
    this.roles = null;
    this.roleChange.next(null);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  setTokenInfo(token: string) {
    const decodedToken = token ? this.getDecodedAccessToken(token) : null;
    this.userName = decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] : null;
    this.userId = decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] : null;
    this.organizationName = decodedToken ? decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'] : null;
    const role = decodedToken ? decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : null;;
    if (role) {
      this.roles = Array.isArray(role) ? role : [role];
    } else {
      this.roles = null;
    }

    this.roleChange.next(this.roles);
  }

  userInRole(roles: string[]): boolean {    
    if (!roles || !this.roles) {
      return false;
    }

    for (let index = this.roles.length - 1; index >= 0; --index) {
      if (roles.indexOf(this.roles[index]) !== -1) {
        return true;
      }
    }

    return false;
  }
}
