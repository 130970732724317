import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstants } from '../constants/app.url.constants';
import { Errand } from '../models/errand.model';

@Injectable()
export class ErrandEmployeesService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) {
  }


  getErrandEmployees(id: string) {
    return this.http.get(this.baseUrl + UrlConstants.ErrandEmployees.Get.replace(':errandId', id));
  }

  createErrandEmployee(errand: Errand[]) {
    return this.http.post(this.baseUrl + UrlConstants.ErrandEmployees.Create, errand);
  }

  deleteErrandEmployee(id: string) {
    return this.http.delete(this.baseUrl + UrlConstants.ErrandEmployees.Delete.replace(':errandId', id));
  }
}
