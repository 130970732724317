import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { UrlConstants } from '../constants/app.url.constants';
import { Employee } from '../models/employee.model';
import { EmployeeObjectsOrganization } from '../models/employeeobject.model';
import { TableParams } from '../models/table-params.model';
import { TableResults } from '../models/table-results';
import { NotificationService } from './notification.service';

@Injectable()
export class EmployeeObjectsService {

  constructor(private http: HttpClient,
    private notificationService: NotificationService,
    @Inject('BASE_URL') protected baseUrl: string) { }


  getEmployeeObjects(id: any){    
    return this.http.get(this.baseUrl + UrlConstants.EmploeeObjectsOrganization.Get.replace(':emploeeId', id));
  }


  createEmployeeObjects(employee: EmployeeObjectsOrganization[]) {
    return this.http.post(this.baseUrl + UrlConstants.EmploeeObjectsOrganization.Create, employee);
  }

  deleteEmployeeObjects(employee: EmployeeObjectsOrganization[]) {
    return this.http.post(this.baseUrl + UrlConstants.EmploeeObjectsOrganization.Delete, employee);
  }
}
