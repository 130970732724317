import { Role } from "./role.model";

export class ManagersView {
  constructor(
    public managers: Manager[]
  ) { }
}

export class Manager {
  constructor(
    public id?: string,
    public login?: string,
    public password?: string,
    public confirmPassword?: string,
    public role?: Role,
  ) { }
}
