import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { QRCodeModule } from 'angular2-qrcode';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

// Компоненты
import { AppComponent } from './app.component';
import { AdminComponent } from './component/admin/admin.component';
import { AdministrationComponent } from './component/admin/administration/administration.component';
import { SettingsComponent } from './component/admin/administration/settings/settings.component';
import { EmployeesComponent } from './component/admin/employees/employees.component';
import { ErrandComponent } from './component/admin/errand/errand.component';
import { NavMenuComponent } from './component/admin/nav-menu/nav-menu.component';
import { ReportsComponent } from './component/admin/reports/reports.component';
import { TimesheetComponent } from './component/admin/reports/timesheet/timesheet.component';
import { WorkdayComponent } from './component/admin/reports/workday/workday.component';
import { WorkdayOneComponent } from './component/admin/reports/workday_one/workday_one.component';
import { FooterComponent } from './component/footer/footer.component';
import { LoginComponent } from './component/login/login.component';
import { StaffComponent } from './component/staff/staff.component';
import { OrganizationsComponent } from './component/admin/organizations/organizations.component';
import { ObjectOrganizationsComponent } from './component/admin/objectorganizations/objectorganizations.component';
import { ForbiddenComponent } from './component/forbidden/forbidden.component';
import { OrgSettingsComponent } from './component/admin/org-settings/org-settings.component';

// Сервисы
import { JwtHttpInterceptor } from './interceptors/jwt-http.interceptor';
import { MonthFormatPipe } from './pipes/month-format.pipe';
import { ActionTokenService } from './services/action-token.service';
import { AppService } from './services/app.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthorizationCheckService } from './services/authorization-check.service';
import { EmployeeService } from './services/employee.service';
import { NotificationService } from './services/notification.service';
import { ReportService } from './services/report.service';
import { SettingService } from './services/setting.service';
import { SignalRService } from './services/signal-r.service';
import { TimeZoneService } from './services/time-zone.service';
import { ManagerService } from './services/manager.service';
import { OrganizationSettingsService } from './services/organization-settings.service';
import { OrganizationsGuard } from './guards/organizations.guard';
import { EmployeeGuard } from './guards/employee.guard';
import { ErrandGuard } from './guards/errand.guard';
import { MultiSelectModule } from 'primeng/multiselect';
import { ReportOrganizationComponent } from './component/admin/reports/organization/report-organization.component';
import { OrganizationService } from './services/organization.service';
import { ChartWorkService } from './services/chart-work.service';
// Определение маршрутов
const appRoutes: Routes = [
  { path: '', component: StaffComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminComponent, children: [
    { path: '', redirectTo: 'settings', pathMatch: 'full' },
    { path: 'settings', component: AdministrationComponent, canActivate: [AuthorizationCheckService, OrganizationsGuard] },
    { path: 'organizations', component: OrganizationsComponent, canActivate: [AuthorizationCheckService, OrganizationsGuard] },
    { path: 'objectorganizations', component: ObjectOrganizationsComponent, canActivate: [AuthorizationCheckService] },
    { path: 'org-settings', component: OrgSettingsComponent, canActivate: [AuthorizationCheckService] },
    { path: 'employees', component: EmployeesComponent, canActivate: [AuthorizationCheckService, EmployeeGuard] },
    { path: 'errand', component: ErrandComponent, canActivate: [AuthorizationCheckService,ErrandGuard] },
    { path: 'reports', component: ReportsComponent, canActivate: [AuthorizationCheckService], children: [
      { path: 'timesheet', component: TimesheetComponent },
      { path: 'workday', component: WorkdayComponent },
      { path: 'workday_one', component: WorkdayOneComponent },
      { path: 'organization', component: ReportOrganizationComponent }]
    }]
  },
  { path: 'forbidden', component: ForbiddenComponent }
];


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    AdminComponent,
    EmployeesComponent,
    ErrandComponent,
    AdministrationComponent,
    ReportsComponent,
    StaffComponent,
    SettingsComponent,
    TimesheetComponent,
    WorkdayComponent,
    WorkdayOneComponent,
    FooterComponent,
    OrganizationsComponent,
    ObjectOrganizationsComponent,
    ForbiddenComponent,
    OrgSettingsComponent,
    ReportOrganizationComponent
  ],
  imports: [
    QRCodeModule,
    DropdownModule,
    TableModule,
    CalendarModule,
    KeyFilterModule,
    ButtonModule,
    TooltipModule,
    AccordionModule,
    TableModule,
    InputTextModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    ToastModule,
    TableModule,
    FileUploadModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [
    DatePipe,
    AuthorizationCheckService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtHttpInterceptor,
      multi: true
    },
    EmployeeService,
    ActionTokenService,
    SignalRService,
    SettingService,
    TimeZoneService,
    ReportService,
    MessageService,
    ConfirmationService,
    NotificationService,
    MonthFormatPipe,
    AppService,
    OrganizationsGuard,
    EmployeeGuard,
    ErrandGuard,
    ManagerService,
    OrganizationSettingsService,
    OrganizationService,
    ChartWorkService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
