import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ObjectOrganization } from '../models/objectorganization.model';
import { UrlConstants } from '../constants/app.url.constants';

@Injectable()
export class ObjectOrganizationService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) {
  }

  getObjectOrganizations() {
    return this.http.get(this.baseUrl + UrlConstants.ObjectOrganization.All);
  }

  getObjectOrganization(id: string) {
    return this.http.get(this.baseUrl + UrlConstants.ObjectOrganization.Get.replace(':objectorganizationsId', id));
  }

  createObjectOrganization(objectOrganization: ObjectOrganization) {
    return this.http.post(this.baseUrl + UrlConstants.ObjectOrganization.Create, objectOrganization);
  }

  updateObjectOrganization(objectOrganization: ObjectOrganization) {
    return this.http.put(this.baseUrl + UrlConstants.ObjectOrganization.Update.replace(':objectorganizationsId', objectOrganization.id), objectOrganization);
  }
  deleteObjectOrganization(id: string) {
    return this.http.delete(this.baseUrl + UrlConstants.ObjectOrganization.Delete.replace(':objectorganizationsId', id));
  }
}
