import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UrlConstants } from '../constants/app.url.constants';
import { ActionTokenEnum } from '../enums/action-token.enum';
import { ActionToken } from '../models/action-token.model';

@Injectable({
  providedIn: 'root'
})
export class ActionTokenService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) { }

  getToken(a: ActionTokenEnum,connectionId: string): Observable<ActionToken> {
    return this.http.get(this.baseUrl + UrlConstants.Token.Generate.replace(':type', a.toString(),).replace(':connect', connectionId,),).pipe(
      map((res: ActionToken) => new ActionToken(res.id, res.create, res.dueDate, res.actionDate, res.action, res.employeeId))
    );
  }
}
