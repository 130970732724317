import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FileUpload, FileUploadModule } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { ApiStatusEnum } from 'src/app/enums/api-status.enum';
import { ApiResponse } from 'src/app/models/api-response.model';
import { TableParams } from 'src/app/models/table-params.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import * as XLSX from 'xlsx';
import { Employee } from '../../../models/employee.model';
import { EmployeeService } from '../../../services/employee.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ObjectOrganizationService } from 'src/app/services/objectorganization.service';
import { ChartWorkService } from 'src/app/services/chart-work.service';
import { ObjectOrganization } from 'src/app/models/objectorganization.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { EmployeeObjectsService } from 'src/app/services/employeeobjects.service';
import { EmployeeObjectsOrganization } from 'src/app/models/employeeobject.model';
import { ChartWork } from 'src/app/models/chart-work.model';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css'],
  providers: [EmployeeService, ObjectOrganizationService, EmployeeObjectsService, AuthenticationService]
})
export class EmployeesComponent implements OnInit {
  employee: Employee = new Employee();
  employees: Employee[];
  employeesCount = 0;
  createMode: boolean = false;
  emptyValue: boolean;
  employeeForm: FormGroup;

  filterValue='';

  errorVisible: boolean = false;
  errorText: string = "";
  public userRoles;
  importDialogDisplay: boolean = false;
  importUploadFile: File = null;
  applyInProgress = false;
  employeeCreateInProgress = false;
  uploadInProgress = false;
  changesMaded: boolean = false;

  objectsOrganization: ObjectOrganization[] = []; 
  chartsWork: ChartWork[] = [];
  newChartWork: ChartWork = new ChartWork();
  public loadingTable = true;
  public rows = 10;
  public initTableParams: TableParams = null;
  public lastEmployeeParams: TableParams = new TableParams();

  @ViewChild(Table) employeeTable: Table;

  constructor(private employeeObjectsService: EmployeeObjectsService,
              private employeeService: EmployeeService,
              private objectOrganizationService: ObjectOrganizationService,
              private chartWorkService: ChartWorkService,
              private notificationService: NotificationService,
              private signalRService: SignalRService,
              private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private datepipe: DatePipe,
              private router: Router) {
    FileUpload.prototype.formatSize = function (bytes) {
      if (bytes == 0) {
        return '0 B';
      }
      var k = 1000,
          dm = 1,
          sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    this.employeeForm = new FormGroup({
      fio: new FormControl(this.employee.fio, [Validators.required]),
      inn: new FormControl(this.employee.inn, [Validators.required]),
      objects: new FormControl(this.objectsOrganization.map(a => ({...a}))),
      chartWork: new FormControl(this.chartsWork.find(c => c.name == 'Дневной')),
    });
    
  }

  
  ngOnInit() {
    this.userRoles = this.authenticationService.roles;
    this.authenticationService.roleChange.subscribe(() => {
      this.userRoles = this.authenticationService.roles;
    });
    
    if (this.activatedRoute.snapshot.queryParams) {
      var temp = new TableParams(this.activatedRoute.snapshot.queryParams);     
      if (temp instanceof TableParams) {
        this.initTableParams = temp;
        this.filterValue = this.initTableParams.globalFilter;
        this.rows = temp.rows;

        this.employeeTable.first = temp.first;
        this.employeeTable.sortField =  temp.sortField;
        this.employeeTable.sortOrder = temp.sortOrder;
      }
    }
    
    this.signalRService.onUpdateEmployee((employee: Employee) => {
      if (this.employees.length > 0){
        let index = this.employees.findIndex(x => x.id === employee.id);
        if (index != -1) {
          this.employees[index] = employee;
        }
      }
    });
    
  }

  onChangeFilter(event){
    this.employeeTable.filterGlobal(event, 'contains');
  }

  loadEmployees(params?, employeeObjects?: ObjectOrganization[]) {
    if (this.initTableParams){
      params = this.initTableParams;
      this.initTableParams = null;
    }

    let tableParams = new TableParams(params, this.filterValue);
    if (params) {
      this.loadingTable = true;
      this.lastEmployeeParams = tableParams;
    } else {
      tableParams = this.lastEmployeeParams;
    }

    let navigationExtras: NavigationExtras = {
      queryParams: tableParams
    };

    this.router.navigate([], navigationExtras);
    
    this.employeeService.getEmployeesTable(tableParams).subscribe(
      result => {
        this.employees = result.data;
        this.chartWorkService.getChartsWork().subscribe((data: ChartWork[])=>{         
          this.chartsWork = data;
          this.newChartWork = this.chartsWork.find(c => c.id == 1);
          this.employees.forEach( el => {el.chartWork = this.chartsWork.find(a => a.id == el.chartWorkId)});
        });
        this.objectOrganizationService.getObjectOrganizations().subscribe((data: ObjectOrganization[])=>{         
          this.objectsOrganization = data;
          this.objectsOrganization.forEach(object => {object.select = false; object.is_del=false});
          if (employeeObjects){
            var employee = this.employees.filter(x => x.inn == this.employee.inn)[0];
            var objects = [];
            employeeObjects.forEach((object:ObjectOrganization)=>{
              var employeeObject = new EmployeeObjectsOrganization();
              employeeObject.employeeId = employee.id;
              employeeObject.objectId = object.id;
              objects.push(employeeObject);
              
            });
            if (objects.length > 0){
              this.addObject(objects);
            }
          }
          this.employees.forEach( el => {this.getObjects(el);});
        });
        
        this.employeesCount = result.count;
        this.loadingTable = false;
        this.changesMaded = false;
      }, error => {
        this.loadingTable = false;
      }
    ); 
  }


  getObjects(employee: Employee){
    this.employeeObjectsService.getEmployeeObjects(employee.id).subscribe((data: any) =>{
      employee.objects = data;
      employee.objectsName = [];
      if (employee.objects.length !== 0){
        employee.objects.forEach((object:ObjectOrganization) =>{
          object.select = true;
        });
        this.objectsOrganization.forEach((object:ObjectOrganization) => {
          var check_item = employee.objects.map(sel => sel.id).indexOf(object.id);          
          if(check_item == -1) employee.objects.push(object);
        });
        employee.objects.forEach((object:ObjectOrganization) => {if (object.select) employee.objectsName.push(object.name)});
      }else{
        employee.objects = this.objectsOrganization.map(a => ({...a}));            
      } 
    });
  }
  // Добавление/редактирование сотрудника в БД
  save() {
    if (!this.employeeForm.controls['fio'].valid) {
      this.notificationService.Error("Заполните ФИО");
      return;
    }

    if (!this.employeeForm.controls['inn'].valid) {
      this.notificationService.Error("Заполните ИНН");
      return;
    }

    if (!this.employeeForm.controls['chartWork'].valid) {
      this.notificationService.Error("Заполните график работы");
      return;
    }
    this.employee.fio = this.employeeForm.controls['fio'].value;
    this.employee.inn = this.employeeForm.controls['inn'].value;
    this.employee.chartWorkId = this.employeeForm.controls['chartWork'].value.id;
    this.employee.objects = this.employeeForm.controls['objects'].value;
    if (this.employee.id == null) {
      this.employeeCreateInProgress = true;
      this.employeeService.createEmployee(this.employee).subscribe((data) => {
        let error = data as ApiResponse;

        this.notificationService.FromApiResponse(error);

        if (error.statusType == ApiStatusEnum.Success) {
          this.loadEmployees(null,this.employee.objects); 
          this.cancel(false);
        }
        this.employeeCreateInProgress = false;
      });
    } else {
      this.employeeCreateInProgress = true;
      this.employeeService.updateEmployee(this.employee,this.signalRService.getConnectionId()).subscribe((data) => {
        this.notificationService.FromApiResponse(data);
        this.loadEmployees();
        this.cancel(false);
        this.employeeCreateInProgress = false;
      });
    }
  }

  editEmployee(e: Employee) {
    this.employee = e;
  }

  cancel(flag: boolean = true) {
    if(flag)
    {
      this.employee = new Employee();
    }
    
    this.createMode = false;
    this.employeeForm.reset();
  }

  removeEmployee(e: Employee) {
    this.notificationService.Confirm(
      'Удаление сотрудника',
      'Вы действительно хотите удалить сотрудника ' + e.fio + '? Также будут удалены все данные о рабочих днях сотрудника.',
      async () => this.employeeService.deleteEmployee(e.id).subscribe((data) => {
        this.notificationService.FromApiResponse(data);

        this.loadEmployees()
      }));
  }

  add() {
    this.cancel();
    this.employeeForm.setValue(({ 
      fio: '',
      inn: '',
      objects: this.objectsOrganization.map(a => ({...a})),
      chartWork: this.chartsWork.find(c => c.name == 'Дневной'),
    }));  
    this.createMode = true;
  }

  removeMobileId(e: Employee) {
    e.mobileId = null;
    this.employeeService.updateEmployee(e,this.signalRService.getConnectionId()).subscribe(data => this.loadEmployees());
  }

  exportToExcel() {
    this.employeeService.getEmployeesExport().subscribe((data) => {
      var file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var fileURL = URL.createObjectURL(file);

      let a = document.createElement("a");
              document.body.appendChild(a);
              a.style.display = "none";
              a.href = fileURL;
              a.target = "_blank";
              a.download = this.datepipe.transform(new Date(), 'dd-MM-yyyy') + " Экспорт сотрудников.xlsx";
              a.click();
              a.remove();
    });
  }

  getEmployeesData() {
    let newArray = this.employees.map(({ id, ...keepAttrs }) => keepAttrs);
    return newArray;
  }

  showImportDialog() {
    this.importDialogDisplay = true;
  }

  onUpload(event) {
    this.uploadInProgress = true;

    for (let file of event.files) {
      this.importUploadFile = file;
    }
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let arrayBuffer = fileReader.result as ArrayBuffer;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var employees = XLSX.utils.sheet_to_json(worksheet, { raw: true }).map(function (e) {
        e['FIO'] = e['ФИО'];
        e['INN'] = e['ИНН'];
        e['objectOrganizationName'] = e['Объект организации'];
        e['MobileId'] = e['ID смартфона'];
        delete e['ФИО'];
        delete e['ИНН'];
        delete e['Объект организации'];
        delete e['ID смартфона'];
        return e;
      });

      this.employeeService.setEmployees(employees).subscribe((data) => {
        this.uploadInProgress = false;
        this.importDialogDisplay = false;

        this.notificationService.FromApiResponse(data);

        this.loadEmployees();
      });
    }
    fileReader.readAsArrayBuffer(this.importUploadFile);
  }
  addObject(employeeObjects:EmployeeObjectsOrganization []){   
    this.employeeObjectsService.createEmployeeObjects(employeeObjects).subscribe((data) => {
      let error = data as ApiResponse;
      this.employeeCreateInProgress = false;
    });
  }

  deObject(employeesobjects:EmployeeObjectsOrganization []){
    this.employeeObjectsService.deleteEmployeeObjects(employeesobjects).subscribe((data) => {
      let error = data as ApiResponse;
      this.employeeCreateInProgress = false;
    });
  }
  onApplyButtonClicked() {
    this.applyInProgress = true;
    this.setChangesMaded(false);
    var editedEmployees = this.getEditedEmployees();
    editedEmployees.forEach((em: Employee)=>{
      var objects = [];
      var objectsDel = [];
      em.objects.forEach((object: ObjectOrganization) => {
        var employeeObject = new EmployeeObjectsOrganization();
        employeeObject.employeeId = em.id;
        employeeObject.objectId = object.id;
        if (object.select == true && object.is_del == false){
          objects.push(employeeObject);
        }else if (object.is_del == true){
          objectsDel.push(employeeObject);
        }
      });
      if (objects.length > 0){
        this.addObject(objects);
      }
      if (objectsDel.length > 0){
        this.deObject(objectsDel);
      }
      
    })
   
    this.employeeService.updateEmployees(editedEmployees, this.signalRService.getConnectionId()).subscribe((data) => {
      this.notificationService.FromApiResponse(data);

      let error = data as ApiResponse;
      if (error.statusType == ApiStatusEnum.Success) {
        this.loadEmployees();
      }
      
      this.applyInProgress = false;
    });
  }

  getEditedEmployees() {
    return this.employees.filter((e) => e.edited == true);
  }

  showError(msg: string) {
    this.errorText = msg;
    this.errorVisible = true;
  }

  setChangesMaded(status: boolean, employee?: Employee) {
    this.changesMaded = status;
    if (employee) {
      employee.edited = true;
    }
  }

  setChangesObjects(object: ObjectOrganization, employee: Employee, flag, changesMaded) {    
    this.changesMaded = changesMaded;
    if (flag){
      employee.objectsName.push(object.name);
      object.is_del = false;
    }else{
      const index = employee.objectsName.indexOf(object.name);
      if (index > -1) {
        employee.objectsName.splice(index, 1); 
      }
      object.is_del = true;
      object.select = false;
    }
    if (employee) {
      employee.edited = true;
    }
  }

  setChangesChartWork(status: boolean, employee?: Employee) {    
    this.changesMaded = status;
    employee.chartWorkId = employee.chartWork.id;
    if (employee) {
      employee.edited = true;
    }
  }

  isEmpty(input) {
    return input.replace(/\s+/g, '') === "";
  }
}
