import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Organization } from '../models/organization.model';
import { UrlConstants } from '../constants/app.url.constants';
import { ManagerRequest } from '../models/manager-request.model';
import { RoleUpdate } from '../models/role-update.model';

@Injectable()
export class ManagerService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) {
  }

  getOrganizations() {
    return this.http.get(this.baseUrl + UrlConstants.Organizations.All);
  }

  getOrganizationsByManagerId(id: string) {
    return this.http.get(this.baseUrl + UrlConstants.Managers.GetOrganizationsByManagerId.replace(':id', id));
  }

  getManagersByOrganization(organization: Organization) {
    return this.http.post(this.baseUrl + UrlConstants.Managers.GetByOrganization, organization);
  }

  createManager(requestData: ManagerRequest) {   
    return this.http.post(this.baseUrl + UrlConstants.Managers.Create, requestData);
  }

  updateManager(requestData: ManagerRequest) {
    return this.http.put(this.baseUrl + UrlConstants.Managers.Update, requestData);
  }

  deleteManager(id: string) {
    return this.http.delete(this.baseUrl + UrlConstants.Managers.Delete.replace(':id', id));
  }
  updateRoleManager(newRole: RoleUpdate) {
    return this.http.post(this.baseUrl + UrlConstants.Managers.UpdateRole, newRole);
  }
}
