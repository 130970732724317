import { Inject, Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { HubConnection } from '@aspnet/signalr';

import { ActionTokenResult } from '../models/action-token-result.model';
import { Employee } from '../models/employee.model';
import { AuthenticationService } from './authentication.service';
import { ObjectOrganization } from '../models/objectorganization.model';
@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private hubConnection: HubConnection;
  public connectionId: string;            // ID соединения с сервером

  constructor(@Inject('BASE_URL') private baseUrl: string,
              private authenticationService: AuthenticationService) {
    this.createConnectionHub();
  }

  public unsubscribeAll() {
    this.hubConnection.off('SuccessToken');
    this.hubConnection.off('ErrorToken');
  }

  public onSuccessToken(action: (actionTokenResult: ActionTokenResult) => any) {
    this.hubConnection.on('SuccessToken', action);
  }

  public onErrorToken(action: (actionTokenResult: ActionTokenResult) => any) {
    this.hubConnection.on('ErrorToken', action);
  }

  public onUpdateEmployee(action: (employee: Employee) => any) {
    this.hubConnection.on('UpdateEmployee', action);
  }

  public onUpdateEmployees(action: (employee: Employee[]) => any) {
    this.hubConnection.on('UpdateEmployees', action);
  }

  public onUpdateObjectOrganization(action: (objectOrganization: ObjectOrganization) => any) {
    this.hubConnection.on('UpdateObjectOrganization', action);
  }

  public onUpdateObjectOrganizations(action: (objectOrganization: ObjectOrganization[]) => any) {
    this.hubConnection.on('UpdateObjectOrganizations', action);
  }

  private createConnectionHub() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl + '/frontend', signalR.HttpTransportType.LongPolling)
      .configureLogging(signalR.LogLevel.Debug)
      .build();
    this.hubConnection.start()
      .then(() => this.getConnectionId())
      .catch(err => console.error(err.toString()));

    const self = this;
    this.hubConnection.onclose(function () {
      setTimeout(function () {
        self.hubConnection.start().catch(err => console.error(err.toString()));
        console.error('hub reconnected');
      }, 5000); // Restart connection after 5 seconds.
    });
  }

  public getConnectionId() {
    this.hubConnection.invoke('getconnectionid').then(
      (data) => {
        this.connectionId = data;
      }
    );
    return this.connectionId;
  }
}
