import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlConstants } from '../constants/app.url.constants';
import { text } from '@angular/core/src/render3';
import { OrganizationSettings } from '../models/organizationsettings.model';

@Injectable()
export class OrganizationSettingsService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') protected baseUrl: string) {
  }

  getOrganizationSettings() {
    return this.http.get(this.baseUrl + UrlConstants.OrganizationSettings.GetSettings);
  }

  setOrganizationSettings(settings:  OrganizationSettings) {
    return this.http.post(this.baseUrl + UrlConstants.OrganizationSettings.SetSettings, settings);
  }
  generateToken() {
    return this.http.get(this.baseUrl + UrlConstants.OrganizationSettings.GenerateToken);
  }
}
