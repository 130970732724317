export class ObjectOrganization {
  constructor(
    public id?: string,
    public name?: string,
    public timeZone?: string,
    public latitude?: string,
    public longitude?: string,
    public organizationId?: string,
    public organizationName?: string,
    public accuracyError?: string,
    public select?: boolean,
    public is_del?: boolean,
  ) { }
}
