import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { ApiResponse } from '../models/api-response.model';

@Injectable()
export class NotificationService {

  constructor(private messageService: MessageService,
              private confirmationService: ConfirmationService) {
  }

  public Success(message: string, title?: string) {
    this.messageService.add({severity: 'success', summary: title ? title : 'Успешно', detail: message});
  }

  public Warn(message: string, title?: string) {
    this.messageService.add({ severity: 'warn', summary: title ? title : 'Предупреждение', detail: message });
  }

  public Error(message: string, title?: string) {
    this.messageService.add({severity: 'error', summary: title ? title : 'Ошибка', detail: message});
  }

  public FromApiResponse(apiResponse: ApiResponse) {
    if (apiResponse.statusType === 0) {
      this.Error(apiResponse.body);
      for (const errorMessage of apiResponse.body) {
        console.error(errorMessage);
      }
    }

    if (apiResponse.statusType === 1) {
      this.Success(apiResponse.body);
    }

    if (apiResponse.statusType === 2) {
      this.Warn(apiResponse.body);
    }
  }

  public FromApiResponseErrand(apiResponse: ApiResponse) {
    if (apiResponse.statusType === 0) {
      this.Error(apiResponse.body);
      for (const errorMessage of apiResponse.body) {
        console.error(errorMessage);
      }
    }

    if (apiResponse.statusType === 1) {
      var response = JSON.parse(apiResponse.body);
      this.Success(response.msg);
      return response.id;
    }

    if (apiResponse.statusType === 2) {
      this.Warn(apiResponse.body);
    }
  }

  public Confirm(title: string = 'Подтверждение', message: string = 'Вы уверены?',
   okCallback: () => any, denyCallback?: () => any) {
    if (title == null) {
      title = 'Подтверждение';
    }
    return this.confirmationService.confirm({
      message: message,
      header: title,
      accept: () => {
        if (okCallback) {
          okCallback();
        }
      },
      reject: () => {
        if (denyCallback != null) {
          denyCallback();
        }
      }
    });
  }

}
