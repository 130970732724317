import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FileUpload, FileUploadModule } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { ApiStatusEnum } from 'src/app/enums/api-status.enum';
import { ApiResponse } from 'src/app/models/api-response.model';
import { TableParams } from 'src/app/models/table-params.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import * as XLSX from 'xlsx';
import { Employee } from '../../../models/employee.model';
import { EmployeeService } from '../../../services/employee.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ObjectOrganizationService } from 'src/app/services/objectorganization.service';
import { EmployeeObjectsService } from 'src/app/services/employeeobjects.service';
import { Errand } from 'src/app/models/errand.model';
import { ManagerService } from 'src/app/services/manager.service';
import { Organization } from 'src/app/models/organization.model';
import { ErrandService } from 'src/app/services/errand.service';
import { check_double } from 'src/app/utils/encryption.util';
import { ErrandEmployee } from 'src/app/models/errand-employee.model';
import { ErrandEmployeesService } from 'src/app/services/errand-employee.service';

@Component({
  selector: 'app-errand',
  templateUrl: './errand.component.html',
  styleUrls: ['./errand.component.css'],
  providers: [EmployeeService, ObjectOrganizationService, EmployeeObjectsService, ErrandService, ErrandEmployeesService]
})
export class ErrandComponent implements OnInit {
  errand: Errand = new Errand();
  errands: Errand[];
  createMode: boolean = false;
  errandCreateForm: FormGroup;
  employeesOrganization: Employee[] =[] ; 
  applyInProgress = false;
  errandCreateInProgress = false;
  uploadInProgress = false;
  organization: Organization;
  public loadingTable = true;
  public rows = 10;
  public initTableParams: TableParams = null;
  public lastEmployeeParams: TableParams = new TableParams();
  public userRoles;
  loadingErrand = false;                          // индикатор загрузки данных об объекте

  errandCreateMode = false;                   // режим создания нового объекта организации
  errandEditMode = false;                     // режим редактирования объекта организации
  errandEditForm: FormGroup;                  // форма для редактирования объекта организации
  errandEditInProgress = false;  
  employeeAddMode = false;   
  errandVeiwMode = false;

  constructor( private employeeService: EmployeeService,
              private errandService: ErrandService,
              private errandEmployeesService: ErrandEmployeesService,
              private notificationService: NotificationService,
              private authenticationService: AuthenticationService,
              private managerService: ManagerService,
              private activatedRoute: ActivatedRoute,
              private datePipe: DatePipe,
              private router: Router) {}

  
  ngOnInit() {

    this.userRoles = this.authenticationService.roles;
    this.authenticationService.roleChange.subscribe(() => {
      this.userRoles = this.authenticationService.roles;
    });
    this.managerService.getOrganizationsByManagerId(this.authenticationService.userId).subscribe((data:Organization) => {
      this.organization = data[0];
      this.loadErrands();
    });
    
    this.errandCreateForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      organizationId: new FormControl(this.errand.organizationId, [Validators.required]),
      latitude: new FormControl(this.errand.latitude),
      longitude: new FormControl(this.errand.longitude),
      accuracyError: new FormControl(0.0035),
      description:new FormControl(this.errand.description),
      employeesSelect: new FormControl([]),
      employees: new FormControl([]),
    });

    this.errandEditForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      organizationId: new FormControl(this.errand.organizationId, [Validators.required]),
      latitude: new FormControl(this.errand.latitude),
      longitude: new FormControl(this.errand.longitude),
      accuracyError: new FormControl(this.errand.accuracyError),
      description:new FormControl(this.errand.description),
      
    });
  }
  getStatus(status){
    switch (status) {
      case 0:
        return "Новое";
      case 1:
        return "Доставлено";
      case 2:
        return "Начато выполнение";
      case 3:
        return "Завершено";
      default:
        return status;
    }
  }

  getEmployee(errand: Errand){
    this.errandEmployeesService.getErrandEmployees(errand.id).subscribe((data: ErrandEmployee[]) =>{
      errand.employeesSelect = data[0];
      errand.employeesSelect.status =  this.getStatus(errand.employeesSelect.status);
      errand.employeesSelect.startErrand =  errand.employeesSelect.startErrand.toString().slice(0, 19).replace('T', ' ');
      errand.employeesSelect.closeErrand =  errand.employeesSelect.closeErrand.toString().slice(0, 19).replace('T', ' ');
      // errand.employeesSelect.forEach((el: ErrandEmployee)=>{
      //   el.status = this.getStatus(el.status);
      //   el.startErrand = this.stringifyDate(el.startErrand);
      //   el.closeErrand = this.stringifyDate(el.closeErrand);
      // })
      errand.employees = [];
      
        this.employeesOrganization.forEach((employee:Employee) => {
          var new_errand = new ErrandEmployee();
          
          new_errand.employee = {...employee};
          // if (errand.employeesSelect.length > 0){
          //   errand.employeesSelect.forEach((employeeErrand:ErrandEmployee) =>{
          //     if(employeeErrand.employee)
          //     {
          //       employeeErrand.employee.select = true;
          //     }
              
          //   });
    
          //   var check_item = errand.employeesSelect.map(sel => {if(sel.employee) return sel.employee.id;}).indexOf(employee.id);          
          //   if(check_item === -1)
          //   {
          //     errand.employees.push(new_errand);
          //   }
          // }else{
          //   errand.employees.push(new_errand);           
          // } 
        });     
    });
  }
  stringifyDate(date: any){
    return this.datePipe.transform(date, 'dd-MM-yyyy hh:mm:ss');
  }
  loadErrands(errandEmployees?: ErrandEmployee[]) {   
    this.loadingErrand = true; 
    
    this.errandService.getErrands()
      .subscribe((data: Errand[]) => {
        this.errands = data;
        this.employeeService.getEmployees().then((em: Employee[])=>{  
          this.employeesOrganization = em;     
          this.employeesOrganization.forEach(object => {object.select = false; object.is_del=false; object.inn_fio = object.inn + ' | '+ object.fio;});
          if (errandEmployees){
            var errand = this.errands.filter(x => x.id == this.errand.id)[0];
            // var delEmployee = this.errand.employees.filter(em => em.employee.is_del == true);
            // delEmployee = delEmployee.map(a => ({...a}));                
            var errands = [];            
            errandEmployees.forEach((employeeErrand:ErrandEmployee)=>{
              
              var employeeObject = new ErrandEmployee();  
              if(employeeErrand){
                employeeObject.employeeId = employeeErrand[0].id;
                employeeObject.errandId = errand.id;
                // employeeObject.startErrand = employeeErrand.startErrand;
                // employeeObject.closeErrand = employeeErrand.closeErrand;
              errands.push(employeeObject);
              } 
                
            });
            // var employeeObject = new ErrandEmployee();  
            // if(errandEmployees.employee){
            //   employeeObject.employeeId = errandEmployees.employee.id;
            //   employeeObject.errandId = errand.id;
            //   // employeeObject.startErrand = employeeErrand.startErrand;
            //   // employeeObject.closeErrand = employeeErrand.closeErrand;
            // errands.push(employeeObject);
            // } 

            if (errands.length > 0){
              this.addErrandEmployee(errands);
            }
            // if(delEmployee.length > 0)
            // {
            //   this.deleteErrandEmployee(delEmployee);
            // }
          }
          this.errands.forEach( el => {this.getEmployee(el);});
          
        });
        this.loadingTable = false;
        this.loadingErrand = false;
      },
      error => { this.loadingErrand = false; });
  }
  deleteErrandEmployee(employeeErrand:ErrandEmployee []){
    employeeErrand.forEach((el:ErrandEmployee)=>{
      this.errandEmployeesService.deleteErrandEmployee(el.id).subscribe((data) => {
        let error = data as ApiResponse;
        this.errandCreateInProgress = false;
      });
    })
    this.loadErrands();
  }
  addErrandEmployee(employeeErrand:ErrandEmployee []){
    console.log('employeeErrand', employeeErrand)
    this.errandEmployeesService.createErrandEmployee(employeeErrand).subscribe((data) => {
      let error = data as ApiResponse;
      this.errandCreateInProgress = false;
    });
    this.loadErrands();
  }
  check_value(){
    if (!this.errandCreateForm.controls['name'].valid) {
      this.notificationService.Error("Заполните название");
      return;
    }  
    var check_arr = ["", null];

    if(check_arr.indexOf(this.errandCreateForm.controls['latitude'].value)== -1 || 
      check_arr.indexOf(this.errandCreateForm.controls['longitude'].value)== -1
      )
    {
      if(check_arr.indexOf(this.errandCreateForm.controls['latitude'].value)!= -1 || 
      check_arr.indexOf(this.errandCreateForm.controls['longitude'].value)!= -1 
      )
      {
        this.notificationService.Error("Не хватает данных. Для определения местоположения необходимо ввести все данные.");
      }
      
      if (!check_double(this.errandCreateForm.controls['latitude'].value)) {
        this.notificationService.Error("Неккоректное число широты. Используйте '.' ");
        return;
      }
      if (!check_double(this.errandCreateForm.controls['longitude'].value)) {
        this.notificationService.Error("Неккоректное число долготы. Используйте '.' ");
        return;
      }
      if (!check_double(this.errandCreateForm.controls['accuracyError'].value)) {
        this.notificationService.Error("Неккоректное число погрешности. Используйте '.' ");
        return;
      }
      
    }     
    if (this.errandCreateForm.controls['employeesSelect'].value.length == 0) {
      this.notificationService.Error("Сотрудник не выбран");
      return;
    }
    return true;
  }
  // Сохранение данных объекта организации
  save() {
    if (!this.check_value()) {
      return;
    }
    
    this.errand.name = this.errandCreateForm.controls['name'].value;
    this.errand.latitude = this.errandCreateForm.controls['latitude'].value;
    this.errand.longitude = this.errandCreateForm.controls['longitude'].value;
    this.errand.accuracyError = this.errandCreateForm.controls['accuracyError'].value;
    this.errand.description = this.errandCreateForm.controls['description'].value;
    this.errand.organizationId = this.organization.id;
   
    if (this.errand.id == null) {
      this.errandCreateInProgress = true; 
      this.errandService.createErrand(this.errand)
        .subscribe((data) => {
          const error = data as ApiResponse;
          this.errand.id = this.notificationService.FromApiResponseErrand(error);
            
          if (error.statusType == ApiStatusEnum.Success) {
            this.loadErrands([this.errandCreateForm.controls['employeesSelect'].value]);
            this.hideErrandCreateDialog();
          }
          this.errandCreateInProgress = false;
        });
    } else {
      this.errandCreateInProgress = true;
      this.errandService.updateErrand(this.errand)
        .subscribe(data => {
          this.notificationService.FromApiResponse(data);
          this.loadErrands();
          this.hideErrandEditDialog();
          this.errandEditInProgress = false;
        });
    }
  }

  //
  edit(errand: Errand) {

    this.errand = errand;
    this.errandCreateForm.setValue({
      name: errand.name,
      organizationId: errand.organizationId,
      latitude: errand.latitude,
      longitude: errand.longitude,
      accuracyError: errand.accuracyError,
      description: errand.description,
      employees: errand.employees,
      employeesSelect: errand.employeesSelect,
    });   
   
    this.errandEditMode = true;
  }
  autogrow(){
    let  textArea = document.getElementById("textarea")       
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';   
  }
  // Удаление объекта организации
  delete(errand: Errand) {
    this.notificationService.Confirm(
      'Удаление поручения',
      'Вы действительно хотите удалить поручение ' + errand.name + '?',
      async () => this.errandService.deleteErrand(errand.id).subscribe((data) => {
        this.notificationService.FromApiResponse(data);
        this.loadErrands();
        this.hideErrandCreateDialog();
        this.errand = new Errand();
      }));
  }

  // Вход в режим создания организации
  add() {
    this.errand = new Errand();
    this.hideErrandCreateDialog();
    this.errandCreateMode = true;
  }

  // Выход из режима создания организации
  hideErrandCreateDialog() {
    this.errandCreateMode = false;
    this.errandCreateForm.reset();
    this.errandCreateForm.setValue({
      name: '',
      organizationId: '',
      latitude: '',
      longitude: '',
      accuracyError: 0.0035,
      description: '',
      employees: [],
      employeesSelect: []

    });
  }
  hideAddDialog() {
    this.employeeAddMode = false;
    this.errandCreateForm.reset();
    
  }
  // Обработчик открытия вкладки с организацией
  onDropdownChange(event) {
    this.errand = event.value;
    // this.getManagersByOrganization(event.value);
  }


  // Скрытие окна переименования организации
  hideErrandEditDialog() {
    this.errandEditMode = false;
    this.errandCreateForm.reset();
    this.loadErrands();
  }
  addEmployees(errand: Errand){
    // this.employeeTable = this.errand.employees;
    errand.employees = [];
    this.employeesOrganization.forEach((employee:Employee) => {
      var new_errand = new ErrandEmployee();     
      new_errand.employee = {...employee};
      // if (errand.employeesSelect){
      //   var check_item = errand.employeesSelect.map(sel => sel.employee.id).indexOf(employee.id);  
      //   if(check_item == -1)
      //   {
      //     errand.employees.push(new_errand);
      //   }        
      // }else{
      //   errand.employees.push(new_errand);
      // }   
    });   
    this.employeeAddMode = true;
  }
  addEmployee(errand: Errand){
    var select = errand.employees.filter((e:ErrandEmployee)=>{if (e.employee.select === true) return e;}).map(a => ({...a}));
    var list_all = errand.employees.filter((e:ErrandEmployee)=>{if (e.employee.select === false) return e;});
    // if(this.errand.employeesSelect)
    // {
    //   this.errand.employeesSelect = [...this.errand.employeesSelect,...select]; 
    // }
    // else{
    //   this.errand.employeesSelect = select;
    // }

    this.errand.employees = list_all.map(a => ({...a}));
    this.employeeAddMode = false;

  }
  
  selectAll(errand: Errand, selectEmployees?: ErrandEmployee[] ){
    this.errand = errand;
    if(selectEmployees != null)
    {
      selectEmployees.forEach((em: ErrandEmployee)=>{
        em.employee.select = true;
      });
      return
    }
    this.errand.employees.forEach((em: ErrandEmployee)=>{
      em.employee.select = true;
    });
  }
  clearAll(errand: Errand){
    this.errand = errand;
    this.errand.employees.forEach((em: ErrandEmployee)=>{
      em.employee.select = false;
    });
  }

  deleteEmployee(errand:Errand, errandEmployee: ErrandEmployee){
    errandEmployee.employee.select = false;
    errandEmployee.employee.is_del = true;
    // errand.employeesSelect.splice(errand.employeesSelect.indexOf(errandEmployee),1);
    errand.employees.push(errandEmployee);
  }
}
