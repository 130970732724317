import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthorizationCheckService implements CanActivate {

  constructor(private router: Router,
              private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('TokenInfo') == null) {     
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }
    if (route.data.expectedRole !== undefined) {
      const hasAccess = this.authenticationService.userInRole(route.data.expectedRole);
      
      if (!hasAccess) {
        this.router.navigate(['/forbidden']);
        return false;
      }
      

    }

    return true;
  }
}
